import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table/table";

export function DataTable({
  columns,
  data,
  onRowClick,
  currentPage = 1,
  totalPages = 1,
  onPageChange,
  className = "",
  loading = false,
}) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const { t } = useTranslation();

  const skeletonRows = Array.from({ length: 10 })

  return (
    <div className={`space-y-6 ${className}`}>
      {/* Table */}
      <div className="rounded-md border border-gray-200 bg-white shadow">
        <Table>
          <TableHeader className="bg-primary-400 text-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow isHeader key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className={`px-4 py-2 text-white font-semibold w-full ${
                      header.column.columnDef.headerClassName || "text-left"
                    }`}
                    style={{ width: header.column.columnDef.size }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
          {loading ? (
            skeletonRows.map((_, index) => (
              <TableRow key={index} className="pointer-events-none">
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={colIndex}
                    className="px-4 py-2 text-gray-300 animate-pulse"
                  >
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={() => onRowClick && onRowClick(row)}
                className={data.length !== 0 ? "hover:bg-gray-100 cursor-pointer" : "hover:none"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="px-4 py-2 text-md text-gray-600"
                  >
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className="cursor-default">
              <TableCell
                colSpan={columns.length}
                className="text-center text-gray-500"
              >
                {t("copilot.noResult")}
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center gap-4">
        <Button
          variant="transparent"
          size="lg"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          {t("copilot.previous")}
        </Button>
        <div className="flex space-x-2 px-4">
        {totalPages > 10 ? (
          <>
            {/* Always show the first page */}
            <Button variant="transparent" size="sm" onClick={() => onPageChange(1)}>
              1
            </Button>
            {currentPage > 5 && <span className="text-gray-500">...</span>}
            {/* Show a range of pages around the current page */}
            {Array.from({ length: 10 }, (_, i) => {
              const page = currentPage - 2 + i; // Show two pages before and after the current page
              if (page > 1 && page < totalPages) {
                return (
                  <Button
                    key={page}
                    variant={page === currentPage ? "solid" : "transparent"}
                    size="sm"
                    onClick={() => onPageChange(page)}
                  >
                    {page}
                  </Button>
                );
              }
              return null;
            })}
            {currentPage < totalPages - 4 && <span className="text-gray-500">...</span>}
            {/* Always show the last page */}
            <Button variant="transparent" size="sm" onClick={() => onPageChange(totalPages)}>
              {totalPages}
            </Button>
          </>
        ) : (
          // If total pages <= 10, show all pages
          Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              variant={i + 1 === currentPage ? "solid" : "transparent"}
              size="sm"
              onClick={() => onPageChange(i + 1)}
            >
              {i + 1}
            </Button>
          ))
        )}
      </div>
        <Button
          variant="transparent"
          size="lg"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          {t("copilot.next")}
        </Button>
      </div>
    </div>
  );
}
