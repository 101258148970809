import { Activity, DollarSign, FileText, Users } from "lucide-react";
import { useTranslation } from "react-i18next";

const iconSize = "w-4 h-4";

export const useSidebarItems = () => {
    const { t } = useTranslation();

    return {
        admin: [
            {
                label: t("sidebar.userManagement"),
                items: [
                    { label: t("sidebar.manageUsers"), icon: <Users className={iconSize} />, path: "/admin/user-management", disabled: false },
                    { label: t("sidebar.manageCompanies"), icon: <FileText className={iconSize} />, path: "/admin/companies-management", disabled: true },
                    { label: t("sidebar.manageActivities"), icon: <FileText className={iconSize} />, path: "/admin/user-activities", disabled: false },
                    // Additional user management options can be uncommented if needed
                    // { label: t("sidebar.userCredits"), icon: <FileText className={iconSize} />, path: "/admin/user-credits", disabled: true },
                    // { label: t("sidebar.linkedAccounts"), icon: <FileText className={iconSize} />, path: "/admin/user-linked-accounts", disabled: true },
                ],
            },
            {
                label: t("sidebar.transactionsManagement"),
                items: [
                    { label: t("sidebar.transactionActivities"), icon: <Activity className={iconSize} />, path: "/admin/transaction-activities", disabled: true },
                    // { label: t("sidebar.pendingTransactions"), icon: <FileText className={iconSize} />, path: "/admin/pending-transactions", disabled: true },
                    // { label: t("sidebar.completedTransactions"), icon: <FileText className={iconSize} />, path: "/admin/completed-transactions", disabled: true },
                ],
            },
            {
                label: t("sidebar.paymentManagement"),
                items: [
                    { label: t("sidebar.managePaymentPlans"), icon: <DollarSign className={iconSize} />, path: "/admin/payment-plans", disabled: false },
                    { label: t("sidebar.paymentHistory"), icon: <FileText className={iconSize} />, path: "/admin/payment-history", disabled: true },
                    { label: t("sidebar.failedPayments"), icon: <FileText className={iconSize} />, path: "/admin/failed-payments", disabled: true },
                    { label: t("sidebar.acceptPayments"), icon: <FileText className={iconSize} />, path: "/admin/accept-payments", disabled: true },
                ],
            },
            {
                label: t("sidebar.logs"),
                items: [
                    { label: t("sidebar.errorLogs"), icon: <FileText className={iconSize} />, path: "/admin/error-logs", disabled: true },
                    { label: t("sidebar.activityLogs"), icon: <Activity className={iconSize} />, path: "/admin/activity-logs", disabled: true },
                ],
            },
        ],
        user: [
            {
                label: t("sidebar.settings"),
                items: [
                    {
                        label: t("sidebar.businessManagement"),
                        icon: <Users className={iconSize} />,
                        path: "/account-settings/manage",
                        disabled: false,
                    },
                    {
                        label: t("sidebar.paymentPlan"),
                        icon: <DollarSign className={iconSize} />,
                        path: "/account-settings/payment-plan",
                        disabled: false,
                    },
                ],
            },
        ],
    };
};
