import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./components/navbar";
import Sidebar from "./components/reusable/SidebarComponent";
import { AuthContext } from "./context/AuthProvider";
import routes from "./routes";

const MainLayout = () => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);

  // Flatten the routes array to include nested children
  const flattenedRoutes = routes.flatMap((route) => (route.children ? route.children : route));
  
  // Check if the current route should display the sidebar
  const shouldShowSidebar = flattenedRoutes.some(
    (route) => route.path === location.pathname && route.showSidebar
  );

  return (
    <div className="flex flex-col w-full">
      {/* Sidebar */}
      {shouldShowSidebar && (
        <div className="w-64 bg-sidebar-accent-foreground fixed h-full z-40">
          <Sidebar role={auth?.role || "user"} />
        </div>
      )}

       {/* Navbar */}
      <div className="fixed top-0 left-0 w-full z-50">
        <Navbar />
      </div>
      {/* Main Content */}
      <div
        className={`flex-1 bg-gray-100 pt-16 pb-16 ${shouldShowSidebar ? "pl-[18rem]" : ""} h-full`}
      >
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout
