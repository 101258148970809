import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { buyPlan, getPaymentPlansByID } from '../apis/payments/paymentApi';
import ConfirmationModal from '../components/confirmationModal';
import CustomButton from '../components/reusable/ButtonComponent';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card/card';
import { Separator } from '../components/ui/separator/separator';
import { AuthContext } from '../context/AuthProvider';
import { useCredits } from '../context/CreditsProvider';
import useAPI from '../hooks/useAPI';
import Spinner from '../components/ui/spinner/spinner';

const PaymentPlan = () => {
    const {t} = useTranslation()
    const {auth} = useContext(AuthContext)
    const { call_getUserCredits } = useCredits();
    const [s_plans, set_s_plans] = useState(null)
    const [s_selectedPlan, set_s_selectedPlan] = useState(null)
    const [s_isModalOpen, set_s_isModalOpen] = useState(false)
    const [s_error, set_s_error] = useState(null)
    const [s_loading, set_s_loading] = useState(false);


    const call_getPaymentPlansByID = useAPI(getPaymentPlansByID)
    const call_buyPlan = useAPI(buyPlan)

    const openConfirmationModal = (plan) => {
        set_s_selectedPlan(plan);
    };

    const handleBuyPlan = () => {
        const payload = {
            user_id: auth.id,
            plan_id: s_selectedPlan.id,
        }

        call_buyPlan.request(payload)
    }

    useEffect(() => {
        set_s_loading(true)
        call_getPaymentPlansByID.request(auth.companyID)
    }, [])

    useEffect(() => {
        if (s_selectedPlan){
            set_s_isModalOpen(true);
        }
    }, [s_selectedPlan])

    useEffect(() => {
        if (call_getPaymentPlansByID.status === "suc"){
            set_s_plans(call_getPaymentPlansByID.data)
        } else if (call_getPaymentPlansByID.status === "err"){
            set_s_error(call_getPaymentPlansByID.msg)
            set_s_loading(false)
        }
    }, [call_getPaymentPlansByID.status])

    useEffect(() => {
        if (call_buyPlan.status === "suc"){
            alert(t("paymentPlan.purchaseSuccess"))
            set_s_isModalOpen(false)
            set_s_selectedPlan(null)
            call_getUserCredits.request(auth.id)
        } else if (call_buyPlan.status === "err"){
            alert(t("paymentPlan.purchaseFailed"))
            set_s_isModalOpen(false)
            set_s_selectedPlan(null)
        }
    }, [call_buyPlan.status])

    useEffect(() => {
        if (s_plans){
            set_s_loading(false)
        }
    }, [s_plans])

    if (s_loading) return (
        <Spinner />
    )
    return (
        <>
            <div className='flex justify-center px-36 py-10'>
                <Card className='w-full items-center p-4'>
                    <CardHeader>
                        <CardTitle className='text-3xl font-extrabold mb-6'>
                            {t("paymentPlan.paymentPlan")}
                        </CardTitle>
                        <p className='text-gray-400 font-medium'>{t("paymentPlan.paymentPlanDesc1")}</p>
                        <p className='text-gray-400 font-medium'>{t("paymentPlan.paymentPlanDesc2")}</p>
                    </CardHeader>

                    <div className='flex justify-center mt-8 my-4 mx-4'>
                        <Separator />
                    </div>

                    <CardContent>
                        <div className='w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 mt-16 justify-between'>
                            {s_plans && s_plans.map((plan, index) => (
                                <Card
                                    key={index}
                                    className={`min-w-[100px] w-full ${plan.is_highlighted ? "shadow-sm shadow-blue-300" : ""} p-6 border hover:shadow-lg`}
                                >
                                    <h3 className="text-lg text-gray-800 font-semibold">{plan.name}</h3>
                                    <p className="text-3xl font-semibold text-gray-800 my-2">
                                        NT{Number(plan.price).toLocaleString()}
                                    </p>
                                    <p className="text-sm text-gray-600">
                                        {t("paymentPlan.planDescription", { plan: plan.credit_amount})}
                                    </p>
                                    <CustomButton 
                                        variant={plan.is_highlighted ? "secondary" : "outline"} 
                                        onClick={() => openConfirmationModal(plan)}
                                        className='w-full px-4 mt-6 rounded-sm font-semibold'>
                                        {t("paymentPlan.buy")}
                                    </CustomButton>
                                </Card>
                            ))}
                        </div>
                    </CardContent>

                    <ConfirmationModal 
                            open={s_isModalOpen}
                            message={t("paymentPlan.confirmModal", {planName: s_selectedPlan ? s_selectedPlan.name : ""})}
                            onConfirm={handleBuyPlan}
                            onCancel={() => set_s_isModalOpen(false)}
                    />
                </Card>
            </div>

        </>
    )
}

export default PaymentPlan
