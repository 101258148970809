import { cn } from "../../../utils/utils";

const Spinner = ({ size = "min-h-screen" }) => {
    return (
        <div className={`flex items-center justify-center ${size}`}>
        <div
            className={cn(
            "w-32 h-32 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"
            )}
        />
        </div>
    );
};

export default Spinner;
