import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DataTable } from './reusable/TableComponent';
import { Badge } from './ui/badge/badge';
import { Check, CheckCircle, CloudAlert, Loader, X, XCircle } from 'lucide-react';

const CaseTable = ({ cases, currentPage, totalPages, onPageChange, s_loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  // console.log("cases", cases)

  const getStatusBadge = (status) => {
    switch (status) {
      case "Done":
        return (
          <Badge className="bg-green-200 text-green-700 font-bold px-3 py-1 flex items-center text-md w-max gap-1">
            <Check size={16} className="text-green-700 font-bold" />
            {t("status.success")}
          </Badge>
        );
      case "PartialDone":
        return (
          <Badge className="bg-yellow-300 text-yellow-700 font-bold px-3 py-1 flex items-center text-md w-max gap-1">
            <Check size={16} className="text-yellow-700" />
            {t("status.partialDone")}
          </Badge>
        );
      case "Error":
        return (
          <Badge className="bg-red-50 text-red-600 font-bold px-3 py-1 flex items-center text-md w-max gap-1">
            <X size={16} className="text-red-600" />
            {t("status.error")}
          </Badge>
        );
      case "Pending":
        return (
          <Badge className="bg-blue-200 text-blue-700 font-bold px-3 py-1 flex items-center text-md w-max gap-1">
            <Loader size={16} className="text-blue-700 animate-spin" />
            {t("status.running")}
          </Badge>
        )
      default:
        return (
          <Badge className="bg-red-50 text-red-600 font-bold px-3 py-1 flex items-center text-md w-max gap-1">
            <CloudAlert size={16} className='text-red-600' />
            {t("status.unknown")}
          </Badge>
        );
    }
  };

  const columns = [
    {
      accessorKey: "title",
      header: t("copilot.caseNameLabel"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.index + 1}. {row.getValue("title")}
        </div>
      ),
      size: cases.length !== 0 ? "25%" : "20%"
    },
    {
      accessorKey: "date",
      header: t("home.tableDate"),
      cell: ({row}) => (
        <span>{row.getValue("date")}</span>
      ),
      size: cases.length !== 0 ? "25%" : "20%"
    },
    {
      accessorKey: "address",
      header: t("copilot.addressLabel"),
      cell: ({row}) => (
        <span>{row.getValue("address")}</span>
      ),
      size: cases.length !== 0 ? "50%" : "45%"
    },
    {
      accessorKey: "status",
      header: t("copilot.statusLabel"),
      cell: ({row}) => (
        getStatusBadge(row.getValue("status"))
      ),
      size: cases.length !== 0 ? "20%" : "35%"
    }
  ]

  const handleRowClick = (row) => {
    navigate(`/cases/${row.original.id}`)
  }

  return (
    <div>
      <DataTable
        columns={columns}
        data={cases}
        onRowClick={handleRowClick}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        loading={s_loading}
      />
    </div>
  );
};

export default CaseTable;
