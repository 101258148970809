import { instance } from "../handlerAPI";

export const login = async (data) => {
    return instance.post(`/api/users/login`, data)
}

export const getUsers = ({page=1, companyID}) => {
    return instance.get("/api/users/all-users?", {
        params: {page, companyID}
    })
}

export const getUserDetails = () => {
    return instance.get('/api/users/details')
}

export const getUserCredits = (userID) => {
    return instance.get(`/api/users/${userID}/credits`)
}

// ------------------ ADMIN PAGE API ---------------------//
export const updateUser = ({userID, userData}) => {
    // console.log("userid, userdata", userID, userData)
    return instance.put(`/api/users/${userID}/update`, userData)
}

export const createUser = (userData) => {
    return instance.post(`/api/users/register`, userData);
}

export const deleteUser = (userID) => {
    return instance.delete(`/api/users/delete/${userID}`);
}

export const getUserActivitiesReport = (userID) => {
    return instance.get(`/api/users/activities-report?user_id=${userID}`)
}