function validateTaiwanID(id) {
    // console.log(`🔎 Validating Taiwan National ID: ${id}`);

    // Regular expression to check format: One letter + nine digits
    const idRegex = /^[A-Z][1289]\d{8}$/;
    if (!idRegex.test(id)) {
        // console.log("❌ Failed: Invalid format");
        return false;
    }

    // Mapping of letters to their corresponding area codes
    const letters = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
    const letterIndex = letters.indexOf(id[0]);
    if (letterIndex === -1) {
        // console.log("❌ Failed: Invalid first letter (not in area codes)");
        return false;
    }

    // Convert the first letter into a two-digit number
    const areaCode = letterIndex + 10;
    const areaCodeDigits = [Math.floor(areaCode / 10), areaCode % 10];

    // Extract the remaining digits from the ID
    const digits = id.slice(1).split('').map(Number);

    // Combine area code digits with ID digits
    const allDigits = [...areaCodeDigits, ...digits];

    // console.log(`📝 Full Digits Array: ${allDigits.join(", ")}`);

    // Weights for checksum calculation
    const weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1];

    // Compute checksum
    let sum = 0;
    for (let i = 0; i < 11; i++) { // Only first 10 digits
        let product = 0
        if(i === 10){
            product = allDigits[i] * 1
        } else {
            product = allDigits[i] * weights[i];
        }
        // console.log(`➡️ Digit: ${allDigits[i]}, Weight: ${weights[i]}, Product: ${product}`);
          sum += product;
    }

    // console.log(`🔢 Total Sum: ${sum}`);
    // console.log(`ℹ️ Modulo 10 Check: ${sum} % 10 = ${sum % 10}`);

    if (sum % 10 === 0) {
        // console.log("✅ Passed: National ID is valid!\n");
        return true;
    } else {
        // console.log("❌ Failed: Checksum validation failed\n");
        return false;
    }
}

export default validateTaiwanID