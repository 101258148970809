import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigationBlockerContext } from "../context/NavigationBlockerContext";

const useNavigationBlocker = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { state, dispatch } = useNavigationBlockerContext();

    useEffect(() => {
        const handlePopState = (event) => {
            // console.log("🔄 Popstate event detected - shouldBlock:", state.isBlocked);
            if (state.isBlocked && location.pathname !== "/login") {
                event.preventDefault();
                dispatch({ type: "SHOW_MODAL", payload: location.pathname });
                window.history.pushState(null, "", location.pathname);
            }
        };

        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [state.isBlocked, location, dispatch]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (state.isBlocked) {
                event.preventDefault();
                event.returnValue = "Are you sure you want to leave? Your changes may not be saved.";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [state.isBlocked]);

    useEffect(() => {
        // console.log("isblocked", state.isBlocked)
        // console.log("pendingpath", state.pendingPath)
        if (!state.isBlocked && state.pendingPath) {
            // console.log("🚀 Navigating to:", state.pendingPath);
            navigate(state.pendingPath, { replace: true }); 
            dispatch({ type: "CANCEL" }); // Reset pendingPath after navigating
        }
    }, [state.isBlocked, state.pendingPath, navigate, dispatch]);


    const attemptNavigation = (path) => {
        // console.log("⚠️ attemptNavigation Called", { isBlocked: state.isBlocked, path });
        if (state.isBlocked) {
            dispatch({ type: "SHOW_MODAL", payload: path });
        } else {
            // console.log("✅ Navigation allowed - going to", path);
            dispatch({ type: "CONFIRM", payload: path });
        }
    };

    const confirmNavigation = (path) => {
        dispatch({ type: "CONFIRM", payload: path });
    };

    const cancelNavigation = () => {
        dispatch({ type: "CANCEL" });
    };

    return {
        isBlocked: state.isBlocked,
        showModal: state.showModal,
        attemptNavigation,
        confirmNavigation,
        cancelNavigation
    };
};

export default useNavigationBlocker;
