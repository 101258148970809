import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import CustomButton from "./reusable/ButtonComponent"
import { Dialog, DialogContent, DialogFooter } from "./ui/dialog/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form/form"
import { Input } from "./ui/input/input"

const AccountEditModal = ({ open, s_username, onConfirm, onCancel, redYesButton, errorMessage }) => {
    const {t} = useTranslation()

    const form = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            username: s_username,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
    });

    const { handleSubmit, watch, reset, formState: { errors } } = form;
    const newPassword = watch("newPassword");

    const handleFormSubmit = (data) => {
        onConfirm(data);
    };

    useEffect(() => {
        reset({
            username: s_username,
            newPassword: "",
            confirmPassword: "",
        });
    }, [s_username, reset]);

    return (
        <Dialog open={open} onOpenChange={onCancel} className="pt-6">
            <DialogContent className='text-center space-y-4'>
                <h3 className="text-xl font-bold">{t("accountSettings.editAccountHeader")}</h3>

                {errorMessage && <p className="text-red-500 text-md">{errorMessage}</p>}

                <Form {...form}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        {/* Username Input */}
                        <FormField
                            name="username"
                            control={form.control}
                            disabled="true"
                            rules={{ required: t("accountSettings.inputError") }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">{t("accountSettings.usernameLabel")}</FormLabel>
                                    <FormControl>
                                        <Input clearable={false} {...field} placeholder={t("accountSettings.enterUsernamePlaceholder")} disabled  />
                                    </FormControl>
                                    <FormMessage>{errors.username?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        {/* Old Password Input */}
                        <FormField
                            name="oldPassword"
                            control={form.control}
                            rules={{ required: t("accountSettings.inputError") }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">{t("accountSettings.enterOldPasswordPlaceholder")}</FormLabel>
                                    <FormControl>
                                        <Input {...field} type="password" />
                                    </FormControl>
                                    <FormMessage>{errors.newPassword?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        {/* New Password Input */}
                        <FormField
                            name="newPassword"
                            control={form.control}
                            rules={{ required: t("accountSettings.inputError") }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">{t("accountSettings.enterNewPasswordPlaceholder")}</FormLabel>
                                    <FormControl>
                                        <Input {...field} type="password" />
                                    </FormControl>
                                    <FormMessage>{errors.newPassword?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        {/* Confirm Password Input */}
                        <FormField
                            name="confirmPassword"
                            control={form.control}
                            rules={{
                                validate: (value) =>
                                value === newPassword || t("accountSettings.passwordMismatchError"),
                            }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">{t("accountSettings.retypeNewPasswordLabel")}</FormLabel>
                                    <FormControl>
                                        <Input {...field} type="password" placeholder={t("accountSettings.retypePasswordPlaceholder")} />
                                    </FormControl>
                                    <FormMessage>{errors.confirmPassword?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <DialogFooter className="flex justify-end mt-4 space-x-4">
                            <CustomButton variant="default" onClick={onCancel}>
                                {t("accountSettings.cancelButton")}
                            </CustomButton>
                            <CustomButton
                                variant="secondary"
                                onClick={handleSubmit(handleFormSubmit)}
                                disabled={!form.formState.isValid || Object.keys(errors).length > 0}
                            >
                                {t("accountSettings.saveButton")}
                            </CustomButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AccountEditModal