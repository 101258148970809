import { Button as ShadcnButton } from "../ui/button/button";
import { cn } from "../../utils/utils";

const CustomButton = ({
    text = "", 
    variant = "default", // Use variant instead of color for consistency
    icon: Icon = null,
    size = "md",
    onClick,
    disabled = false,
    className = "",
    children,
    ...props 
}) => {
    // Define variant classes
    const variantClasses = {
        default: "bg-gray-100 text-gray-800 hover:bg-gray-200",
        blank: "bg-white border border-primary hover:bg-primary hover:text-white text-black",
        outline: "bg-white border border-gray-200 hover:bg-secondary-400 hover:text-white text-black",
        primary: "bg-blue-500 text-white hover:bg-blue-600",
        secondary: "bg-secondary-400 text-white hover:bg-secondary",
        danger: "bg-red-500 text-white hover:bg-red-600",
        success: "bg-green-500 text-white hover:bg-green-600",
        link: "bg-gray-100 text-black hover:bg-gray-500 hover:text-gray-100 border border-gray rounded-none",
        special: "bg-blue-200 rounded-md text-secondary-400 text-xl hover:bg-secondary-400 hover:text-white",
    };

    // Define size classes
    const sizeClasses = {
        sm: "px-3 py-1 text-sm",
        md: "px-4 py-2 text-base",
        lg: "px-6 py-3 text-lg",
    };

    return (
        <ShadcnButton
            className={cn(
                "flex items-center font-bold justify-center rounded-md transition-all",
                variantClasses[variant], // Use variant to determine the color class
                sizeClasses[size],
                disabled && "opacity-50 cursor-not-allowed",
                className
            )}
            onClick={onClick}
            disabled={disabled}
            {...props}
        >
            {children ? (
        children
        ) : (
            <>
            {Icon && <Icon className="mr-2 h-5 w-5" />}
            {text}
            </>
        )}
        </ShadcnButton>
    );
};

export default CustomButton;
