import { instance } from "../handlerAPI"

export const getCompanyCases = ({page = 1, items_per_page = 10}) => {
    return instance.get("/api/cases/company-cases", {
        params: {page, items_per_page}
    })
}

export const getCaseDetails = (caseID) => {
    return instance.get(`/api/cases/${caseID}`)
}