import { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getAccount } from '../apis/account/accountApi';
import { getCompanyCases } from '../apis/cases/casesApi';
import { getBackgroundProcessStatus } from '../apis/script/scriptApi';
import CaseTable from '../components/caseTable';
import ConfirmationModal from '../components/confirmationModal';
import CustomButton from '../components/reusable/ButtonComponent';
import Spinner from '../components/ui/spinner/spinner';
import { AuthContext, withAuthProtection } from '../context/AuthProvider';
import useAPI from '../hooks/useAPI';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const [cases, setCases] = useState([]);
  const [s_screenLoading, set_s_screenLoading] = useState(true);
  const [s_loading, set_s_loading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [s_haveAccount, set_s_haveAccount] = useState(false);
  const [s_isModalOpen, set_s_isModalOpen] = useState(false);
  const [s_noAccountModal, set_s_noAccountModal] = useState(false);
  const [itemsPerPage] = useState(10);  // Number of items per page

  // Memoize cookies object to avoid unnecessary re-creations
  const cookies = useMemo(() => new Cookies(), []);

  const call_getCompanyCases = useAPI(getCompanyCases)
  const call_getBackgroundProcessStatus = useAPI(getBackgroundProcessStatus)
  const call_getAccount = useAPI(getAccount)
  
  const handlePageChange = (pageNumber) => {
    if (currentPage !== pageNumber) {
      set_s_loading(true)
      setCurrentPage(pageNumber)
      call_getCompanyCases.request({ page: pageNumber, items_per_page: 10})
    }
  };

  const handleNewCase = () => {
    call_getBackgroundProcessStatus.request()
  };

  useEffect(() => {
    set_s_loading(true)
    call_getAccount.request()
    call_getCompanyCases.request({ page: 1, items_per_page: 10})
  }, [])

  useEffect(() => {
    if (call_getAccount.status === "suc") {
      set_s_haveAccount(call_getAccount.data.length === 2)
      set_s_screenLoading(false)
    } else if (call_getAccount.status === "err" ){
      console.error(call_getAccount.msg)
      set_s_screenLoading(false)
    }
  }, [call_getAccount.status])

  useEffect(() => {
    if (call_getBackgroundProcessStatus.status === "suc") {
      const isRunning = call_getBackgroundProcessStatus.data.is_running
      // console.log("Isrunning:", isRunning)
      if (isRunning) {
        set_s_isModalOpen(true)
      } else {
        if (s_haveAccount) {
          navigate('/copilot')
        } else {
          set_s_noAccountModal(true)
        }
      }
    } else if (call_getBackgroundProcessStatus.status === "err" ){
      console.error(call_getBackgroundProcessStatus.msg)
    }
  }, [call_getBackgroundProcessStatus.status])


  useEffect(() => {
    if (call_getCompanyCases.status === "suc") {
      const {cases, total_pages} = call_getCompanyCases.data
      setCases(cases)
      setTotalPages(total_pages)
      set_s_loading(false)
    } else if (call_getCompanyCases.status === "err"){
      set_s_loading(false)
    }
  }, [call_getCompanyCases.status])


  if (!auth) {
    return null;
  }

  if (s_screenLoading) {
    return <Spinner />;
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='bg-white w-full max-w-[1280px] mx-36 rounded-md mt-12 pt-12 pb-12 px-12'>
        <div className='flex items-center justify-between mb-6 px-14'>
          <h1 className='text-2xl font-bold'>
            {t("navbar.home")}
          </h1>
          {/* <CustomButton
            text={`+ ${t("copilot.submitButton")}`}
            color="primary-100"
            size="lg"
            onClick={handleNewCase} 
            className='text-white bg-primary-400 hover:bg-white hover:text-black-100'
          >
            + {t("home.submit")}
          </CustomButton> */}
        </div>

        <div className='px-12'>
          <CaseTable 
            cases={cases} 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePageChange}
            s_loading={s_loading}
          />
        </div>
        
      </div>

      <ConfirmationModal 
        open={s_isModalOpen}
        message={t("home.backgroundProcess")}
        onConfirm={() => set_s_isModalOpen(false)}
        onCancel={() => set_s_isModalOpen(false)}
      />

      <ConfirmationModal 
        open={s_noAccountModal}
        message={
          <Trans i18nKey={"home.noAccount"}>
            You don't have linked accounts. Please go to <span className='hover:cursor-pointer text-blue-500 italic underline' onClick={() => navigate("/account-settings/linked-accounts")}>settings</span> and link your account
          </Trans>
        }
        onConfirm={() => set_s_noAccountModal(false)}
        onCancel={() => set_s_noAccountModal(false)}
      />
    </div>
  );
};

export default withAuthProtection(Home);
