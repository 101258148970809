import { AlertTriangle } from "lucide-react";
import { useTranslation } from "react-i18next";
import StatusRow from "../statusRow";

const StatusError = (props) => {
  const { t } = useTranslation();
  return (
    <StatusRow
      fields={props.fields}
      description={props.description}
      status="error"
      icon={<AlertTriangle className="w-4 h-4 text-white" />}
      badgeText={t("status.error") || "錯誤"}
    />
  );
};

export default StatusError;
