import { useTranslation } from 'react-i18next';
import { Button } from './ui/button/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog/dialog';

const siteNameMap = {
    "easymap" : "easymap",
    "illegal_construction": "illegalConstruction",
    "vicinity_map": "vicinityMap",
    "school_district": "schoolDistrict",
    "nearby_landmarks": "googleMaps",
    "road_width": "roadWidth",
    "registry_info": "registryInfo",
    "zoning_map": "zoningMap",
    "tax_estimation": "taxEstimation",
    "user_permit": "userPermit",
    "era_form": "eraPlatformForm",
};

const DownloadPartialModal = ({ open, title, message, websites, onConfirm, onCancel, redYesButton }) => {
    const {t} = useTranslation()

    const formattedWebsites = websites.length > 0 
        ? `${websites.map(website => t(`siteNames.${siteNameMap[website] || website}`)).join(", ")}`
        : "";

    return (
        <Dialog open={open} onOpenChange={onCancel} className="pt-6">
            <DialogContent className='text-center space-y-4'>
                {title && (
                <DialogHeader>
                    <DialogTitle className="text-lg font-bold text-gray-900">{title}</DialogTitle>
                </DialogHeader>
                )}
                <div className='p-4'>
                    <p className='text-lg text-left text-gray-800'>
                        {message}
                        {websites.length > 0 && (
                            <>
                                <span className="text-red-500 font-bold">
                                    {websites.map(website => t(`siteNames.${siteNameMap[website] || website}`)).join(", ")}
                                </span>
                            </>
                        )}
                    </p>
                </div>

                <DialogFooter className='flex justify-center space-x-4'>
                {onCancel && (
                    <Button 
                        className='bg-gray-200 border-black shadow-none hover:bg-gray-300 hover:pointer'
                        onClick={onCancel}>
                    {t("buttons.cancel")}
                    </Button>
                )}
                <Button 
                    className={redYesButton ? "bg-red-500 hover:bg-red-600 text-white" : "bg-secondary-400 font-bold hover:bg-secondary-600 text-white"} 
                    onClick={onConfirm}
                >
                    {t("buttons.yass")}
                </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DownloadPartialModal;
