import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosServer } from '../apis/axiosApi';
import { getUserDetails, updateUser } from '../apis/users/usersAPi';
import AccountEditModal from '../components/accountEditModal';
import CustomButton from '../components/reusable/ButtonComponent';
import { Avatar, AvatarImage } from '../components/ui/avatar/avatar';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card/card';
import { AuthContext } from '../context/AuthProvider';
import useAPI from '../hooks/useAPI';
import ToastComponent from '../components/reusable/ToastComponent';

const userIcon = "/usernameicon.svg"

const AccountSettings = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const { t } = useTranslation();
  const [company, setCompany] = useState('');
  const [s_username, set_s_username] = useState('')
  const [s_error, set_s_error] = useState('');
  const [s_isProfileModalOpened, set_s_isProfileModalOpened] = useState(false);
  const [s_isToastShowing, set_s_isToastShowing] = useState(false);

  const call_getUserDetails = useAPI(getUserDetails)
  const call_updateUser = useAPI(updateUser)

  useEffect(() => {
    call_getUserDetails.request()
  }, [])


  const handleEditProfile = async (formData) => {
    const payload = {oldPassword: formData.oldPassword, newPassword: formData.newPassword}
    call_updateUser.request({userID: auth.id, userData: payload})
    // try{
    //   const response = await axiosServer.put("/api/users/update", {
    //     username: username, password: newPassword
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${auth.accessToken}`,
    //     },
    //   }
    //   );

    //   if (response.status === 200) {
    //     setAuth((prev) => ({
    //       ...prev,
    //       username: username
    //     }));

    //     set_s_error("")
    //     set_s_isProfileModalOpened(false)
    //     call_getUserDetails.request()
    //   }
    // } catch (err) {
    //   if (err.response?.status === 400) {
    //     set_s_error(t("accountSettings.unauthorizedError"))
    //     navigate("/login")
    //   } else {
    //     set_s_error(t("accountSettings.updateError"))
    //   }
    // }
  }

  const goToLinkedAccount = () => {
    navigate("/account-settings/linked-accounts")
  }

  useEffect(() => {
    call_getUserDetails.request()
  }, [auth]);
  
  useEffect(() => {
    if (call_getUserDetails.status === "suc"){
      set_s_username(call_getUserDetails.data.username)
      setCompany(call_getUserDetails.data.company)
    }
  }, [call_getUserDetails.status])

  useEffect(() => {
    if (call_updateUser.status === "suc"){
      call_getUserDetails.request()
      set_s_error("")
      set_s_isProfileModalOpened(false)
      set_s_isToastShowing(true)
    } else if (call_updateUser.status === "err"){
      set_s_error(t("accountSettings.updateError"))
    }
  }, [call_updateUser.status])

  return (
    <>
      <div className='flex justify-center py-10'>
        <Card className='w-full max-w-2xl p-6'>
          <CardHeader>
            <CardTitle className='text-2xl font-bold'>
              {t("accountSettings.header")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className='flex flex-col md:flex-row md:items-center gap-8'>
              <Avatar className='w-24 h-24 bg-gray-200 text-gray500'>
                <AvatarImage src={userIcon} alt="era-user" />
              </Avatar>
              <div>
                <h3 className='text-xl font-semibold'>
                  {t("accountSettings.userProfile")}
                </h3>
                <p className='text-md text-gray-600 mt-4'> 
                  <strong>{t("accountSettings.usernameLabel")}</strong>:
                  {auth?.username}
                </p>
                <p className='text-md text-gray-600 mt-2'>
                  <strong>{t("accountSettings.companyLabel")}</strong>:
                  {company}
                </p>
                <div className='flex gap-4 mt-6'>
                  <CustomButton
                    variant='blank'
                    type="submit"
                    onClick={() => set_s_isProfileModalOpened(true)}
                  >
                    {t("accountSettings.editProfileButton")}
                  </CustomButton>
                  <CustomButton
                    variant='blank'
                    type="submit"
                    onClick={() => goToLinkedAccount()}
                  >
                    {t("accountSettings.linkedAccountsButton")}
                  </CustomButton>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <AccountEditModal 
        open={s_isProfileModalOpened}
        s_username={s_username}
        onConfirm={handleEditProfile}
        onCancel={() => set_s_isProfileModalOpened(false)}
        errorMessage={s_error}
      />

      {s_isToastShowing && (
        <ToastComponent
          title={t("accountSettings.accountUpdated")}
          variant={"success"}
          duration={3000}
          position="top-center"
        />
      )}
    </>
  );
};

export default AccountSettings;
