// src/context/AuthProvider.js

import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate here
import Cookies from 'universal-cookie';
import Spinner from '../components/ui/spinner/spinner';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  useEffect(() => {
    const authData = cookies.get('authData');
    const rememberMe = cookies.get("rememberMe");

    // Check if this is a refresh or a new session
    const isSessionActive = sessionStorage.getItem("sessionActive");

    if (authData) {
      try {
        const parsedAuth = typeof authData === 'string' ? JSON.parse(authData) : authData;
        // console.log('rememberMe:', rememberMe)
        // console.log('parsedauth:', parsedAuth)
        if (rememberMe === true) {
          setAuth(parsedAuth);
          sessionStorage.setItem("sessionActive", "true"); // Mark session as active
        } else {
          if (isSessionActive) {
            setAuth(parsedAuth);
          } else {
            // If window was closed and reopened, logout
            cookies.remove('authData', { path: '/' });
            cookies.remove("rememberMe", { path: "/" });
            setAuth(null);
          }
        }
      } catch (error) {
        console.error("Failed to parse authData:", error);
        cookies.remove('authData', { path: '/' });
        setAuth(null);
      }
    } else {
      setAuth(null);
    }

    setLoading(false);
  }, []); // Remove the cookies dependency by moving its initialization inside useEffect

  useEffect(() => {
    if (auth) {
      // console.log("Setting sessionstorage with auth:", auth)
      sessionStorage.setItem("sessionActive", "true")
    }
  }, [auth])

  // Handle logout when window is closed (only if rememberMe is false)
  useEffect(() => {
    const handleWindowClose = () => {
      if (cookies.get("rememberMe") === "false") {
        cookies.remove("authData", { path: '/' });
        cookies.remove("rememberMe", { path: "/" });
        sessionStorage.removeItem("sessionActive"); // Clear session storage
      }
    };

    window.addEventListener("beforeunload", handleWindowClose);
    
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
    };
  }, []);

  if (loading) {
    return <Spinner /> // Show a loading spinner or message while checking auth
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

// Higher-Order Component for Authentication Protection
export const withAuthProtection = (WrappedComponent) => {
  return (props) => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (!auth?.accessToken) {
        navigate('/login');
      }
    }, [auth, navigate]);

    if (!auth) {
      return null; // or a loading spinner, etc.
    }

    return <WrappedComponent {...props} />;
  };
};

// ProtectedRoute Component for React Router
export const ProtectedRoute = ({ element: Component, requiredRole }) => {
  const { auth } = useContext(AuthContext);
  const cookies = new Cookies();
  const rememberMe = cookies.get("rememberMe");
  const location = useLocation(); // Get the current route

  useEffect(() => {
    localStorage.setItem('lastVisited', location.pathname); // Store last visited route
  }, [location]);


  // Redirect to login if not authenticated
  if (!auth?.accessToken) {
    return <Navigate to="/login" />;
  }

  // Auto logout if rememberMe is false and session was closed
  if (rememberMe === "false" && !sessionStorage.getItem("sessionActive")) {
    cookies.remove("authData", { path: "/" });
    cookies.remove("rememberMe", { path: "/" });
    return <Navigate to="/login" />;
  }

  // Redirect to home if the user does not have the required role
  if (requiredRole && auth.role !== requiredRole) {
    return <Navigate to="/home" />;
  }

  return <Component />;
};
