import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const appVersion = isDev ? '' : process.env.REACT_APP_VERSION || new Date().getTime();

i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'zh',
        fallbackLng: "zh",
        debug: false,
        interpolation: {
        escapeValue: false,
        },
        backend: {
            loadPath: isDev
            ? '/translations/{{lng}}.json'
            : `/translations/{{lng}}.json?v=${appVersion}`,
        }
});

export default i18n;
