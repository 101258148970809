import { useState } from "react";

interface ApiResponse {
    msg: string;
    data: any[];
    status: boolean;
}

type ApiFunction = (params: any) => Promise<ApiResponse | Blob>;

const useAPI = (apiFunc: ApiFunction, isBinary = false) => {
    const [data, setData] = useState<any | null>(null); // Handle binary or JSON data
    const [msg, setMsg] = useState<any>("Loading");
    const [status, setStatus] = useState<"def" | "load" | "suc" | "err">("def");

    const request = async (params: any) => {
        setStatus("load");
        try {
            const res = await apiFunc(params);

            // console.log("Response in useAPI:", res);
            if (isBinary && res instanceof Blob) {
                // console.log("BLOB TYPE")
                // For binary responses, directly store the blob (pdf)
                setData(res as Blob);
                setStatus("suc");
            } else if ((res as ApiResponse)?.status) {
                setData((res as ApiResponse).data);
                setMsg((res as ApiResponse).msg || "Success");
                setStatus("suc");
            } else {
                setMsg((res as ApiResponse)?.msg || "Error occurred");
                setStatus("err");
            }
        } catch (error) {
            console.error("API request failed:", error);
            setMsg("Request failed");
            setStatus("err");
        }
    };

    return { data, status, msg, request };
};

export default useAPI;
