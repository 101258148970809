import {
    ToastProvider,
    Toast,
    ToastTitle,
    ToastDescription,
    ToastClose,
    ToastViewport,
} from "../ui/toast/toast"; 

const ToastComponent = ({
    title = "",
    description = "",
    variant = "default", // Options: 'default', 'success', 'destructive', 'error'
    duration = 2000,
    position = "bottom-right",
    onClose = null,
}) => {
    // Define position classes
    const positionClasses = {
        "top-center": "fixed top-4 left-1/2 transform -translate-x-1/2 p-4 z-[100]",
        "top-right": "fixed top-4 right-4 p-4 z-[100]",
        "bottom-right": "fixed bottom-4 right-4 p-4 z-[100]",
        "bottom-center": "fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 z-[100]",
        "top-left": "fixed top-4 left-4 p-4 z-[100]",
        "bottom-left": "fixed bottom-4 left-4 p-4 z-[100]",
    };

    // Define color variants for toast
    const variantClasses = {
        default: "bg-blue-100 text-blue-800 border border-blue-300", // General blue notification
        success: "bg-green-100 text-green-800 border border-green-300", // Green for success
        destructive: "bg-yellow-100 text-yellow-800 border border-yellow-300", // Yellow for critical/destructive actions
        error: "bg-red-100 text-red-800 border border-red-300", // Red for errors
    };

    return (
        <ToastProvider>
            <Toast
                className={`p-4 rounded-md shadow-md flex flex-col items-start ${variantClasses[variant]}`}
            >
                {/* Title */}
                <ToastTitle className="text-lg font-semibold mb-1">
                    {title}
                </ToastTitle>

                {/* Description */}
                <ToastDescription className="text-sm">
                    {description}
                </ToastDescription>

                {/* Close Button */}
                <ToastClose
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                />
            </Toast>

            {/* ToastViewport with dynamic position */}
            <ToastViewport className={positionClasses[position]} />
        </ToastProvider>
    );
};

export default ToastComponent;