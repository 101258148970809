import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Header, Icon, Label } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import { axiosServer } from '../apis/axiosApi';
import ConfirmationModal from '../components/confirmationModal';
import IDModal from '../components/idModal';
import StatusTable from '../components/statusTable';
import useWebsocket from '../hooks/useWebsocket';
import "../styles/output.css";

const default_status = {
  easymap: "Waiting",
  illegalConstruction: "Waiting",
  vicinityMap: "Waiting",
  schoolDistrict: "Waiting",
  googleMaps: "Waiting",
  roadWidth: "Waiting",
  registryInfo: "Waiting",
  zoningMap: "Waiting",
  taxEstimation: "Waiting",
  userPermit: "Waiting",
  eraPlatformForm: "Waiting"
};

var fileDownload = require('js-file-download');

const Output = () => {
  let location = useLocation();
  const { t } = useTranslation();
  const siteNames = t(".siteNames") || {};
  const siteDescriptions = t(".siteDescriptions") || {};
  const [pdfDownloadable, setPdfDownloadable] = useState(false);
  const [siteStatuses, setSiteStatuses] = useState(default_status);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [s_isPopUpOpen, set_s_isPopUpOpen] = useState(false);
  const [isIdModalOpen, setIsIdModalOpen] = useState(false);
  // const [lands, setLands] = useState([]);
  const [s_ownerships, set_s_ownerships] = useState([])

  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []); 
  const isDownloadableRef = useRef(false); 
  const statusMap = useRef({...default_status})

  // Use Websocket to get scrape status
  const { s_isConnected, sendMessage, s_lastMessage, closeConnection, s_error } = useWebsocket();

  // Mapping of API keys (in Chinese) to frontend keys (in English)
  // const keyMapping = {
  //   '地籍資訊網站': 'easymap',
  //   '臺北市建管業務網站': 'illegalConstruction',
  //   '台灣圖霸': 'vicinityMap',
  //   '學區查詢系統': 'schoolDistrict',
  //   'Google Maps': 'googleMaps',
  //   '地理資訊e點通': 'roadWidth',
  //   '地政資訊e點通': 'registryInfo',
  //   '台北市土地使用分區查詢': 'zoningMap',
  //   '財政部稅務入口網': 'taxEstimation',
  //   '網路執照存根影像查詢': 'userPermit',
  //   'ERA管理後台': 'eraPlatformForm',
  // };

  // const mapApiResponseToSiteStatuses = (apiResponse) => {
  //   const newSiteStatuses = { ...default_status };
  //   for (const [chineseKey, status] of Object.entries(apiResponse)) {
  //     const englishKey = keyMapping[chineseKey];
  //     if (englishKey) {
  //       newSiteStatuses[englishKey] = status;
  //     }
  //   }
  //   return newSiteStatuses;
  // };

  // Mapping of Websocket return message to frontend keys
  const keyMapping = {
    easymap: 'easymap',
    illegal_construction: 'illegalConstruction',
    vicinity_map: 'vicinityMap',
    school_district: 'schoolDistrict',
    nearby_landmarks: 'googleMaps',
    road_width: 'roadWidth',
    registry_info: 'registryInfo',
    tax_estimation: 'taxEstimation',
    user_permit: 'userPermit',
    era_form: 'eraPlatformForm',
    zoning_map: 'zoningMap'
  }


  const handleUnauthorized = useCallback(() => {
    cookies.remove('authData', { path: '/' });
    navigate('/login');
  }, [navigate, cookies]);

  const refreshAccessToken = useCallback(async () => {
    try {
      const authData = JSON.parse(cookies.get('authData'));
      const response = await axiosServer.post('/api/token/refresh/', {
        refresh: authData?.refreshToken,
      });
      const newAccessToken = response.data.access;

      const updatedAuthData = { ...authData, accessToken: newAccessToken };
      cookies.set('authData', JSON.stringify(updatedAuthData), { path: '/', maxAge: 7 * 24 * 60 * 60 });

      return newAccessToken;
    } catch (error) {
      handleUnauthorized();
      return null;
    }
  }, [cookies, handleUnauthorized]);

  // Previous Way : Long Polling
  // const getScrapeStatuses = useCallback(async () => {
  //   try {
  //     // console.log("Fetching scrape statuses...");

  //     let authData = cookies.get('authData');
  //     if (typeof authData === 'string') {
  //       authData = JSON.parse(authData);
  //     }

  //     let accessToken = authData?.accessToken;

  //     if (!accessToken) {
  //       // console.log("No access token, refreshing...");
  //       accessToken = await refreshAccessToken();
  //     }

  //     if (accessToken) {
  //       // console.log("Access token available, sending request...");

  //       const response = await axiosServer.post('api/scrape-status/', {
  //         case_name: location.state.case_name,
  //         case_id: location.state.case_id,
  //       }, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       // console.log("API response received:", response.data);

  //       // Map API response to English keys and update state
  //       const updatedSiteStatuses = mapApiResponseToSiteStatuses(response.data);
  //       // console.log("Updated siteStatuses:", updatedSiteStatuses);

  //       setSiteStatuses(updatedSiteStatuses);

  //       if (!isDownloadableRef.current && (response.data["ERA管理後台"] === "Success" || response.data["ERA管理後台"] === "Error")) {
  //         setPdfDownloadable(true);
  //         alert("點擊綠色 「下載 .pdf」 按鈕, 會下載相關圖片及截圖");
  //         isDownloadableRef.current = true;
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in getScrapeStatuses:", error); 
  //     if (error.response && error.response.status === 401) {
  //       handleUnauthorized();
  //     } else {
  //       console.log("Other error occurred:", error);
  //     }
  //   }
  // }, [location.state.case_name, location.state.case_id, handleUnauthorized, refreshAccessToken, cookies]);


  const getPDF = async () => {
    try {
      let authData = cookies.get('authData');
  
      if (typeof authData === 'string') {
        authData = JSON.parse(authData);
      }
  
      let accessToken = authData?.accessToken;

      if (!accessToken) {
        accessToken = await refreshAccessToken();
      }
  
      if (accessToken) {
        const response = await axiosServer.get(`api/scripts/export-pdf?case_name=${location.state.case_name}&case_id=${location.state.case_id}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        fileDownload(response.data, `${location.state.case_name}.pdf`);
        // console.log('PDF downloaded successfully');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleUnauthorized();
      } else {
        console.error('Error downloading PDF:', error);
      }
    }
  };  


  const checkProcess = async () => {
    const response = await axiosServer.get('/api/scripts/check-process')
    return response.data.is_running
  }

  const goToCopilot = async () => {
    const isRunning = await checkProcess()
    // console.log(`Background Process : ${isRunning}`)
    if (isRunning){
      set_s_isPopUpOpen(true); // Block navigation when a background process is running
    } else {
      setIsModalOpen(true);
    }
  };

  const handleConfirmLeave = () => {
    setIsModalOpen(false);
    navigate('/copilot');
  };

  const handleCancelLeave = () => {
    setIsModalOpen(false);
  };

  const handleSubmitID = (inputType, lands, inputValue) => {
    const payload = {
      case_id: location.state.case_id,
      case_name: location.state.case_name,
      input_data: {
        input_type : inputType,
          values : lands.map((item, index) => {
            return {
              landId: item,
              id : inputValue[index]
            }
          })
      },
      site_name: "registry_info",
      timestamp: new Date().toISOString(),
    }

    // console.log("inputType:", inputType, "inputValue:", inputValue)

    sendMessage(payload)

    setIsIdModalOpen(false)
  }

  // Previous way: Long Polling
  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     getScrapeStatuses();
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalCall);
  //   };
  // }, [getScrapeStatuses]);

  const debounceSetOwnerships = useCallback(debounce((ownerships) => {
      set_s_ownerships(ownerships);
  }, 300), []);

  const debounceUpdateStatus = useCallback(debounce((updateStatuses) => {
    setSiteStatuses(updateStatuses)
  }))

  // Now use Websocket
  useEffect(() => {
    if (s_lastMessage){
      // console.log(`LAST MESSAGE : ${s_lastMessage}`)
      const { case_number, site_name, status, ownerships } = s_lastMessage
      const mappedKey = keyMapping[site_name] || site_name

      if (status === "noOtherRights" && ownerships) {
        debounceSetOwnerships(ownerships)
      }

      // Update the status of each site based on websocket messages
      if (mappedKey in default_status) {
        statusMap.current[mappedKey] = status
        debounceUpdateStatus({ ...statusMap.current });
      }
      
      // Enable PDF download when ERA platform form is completed
      if (!isDownloadableRef.current && (status === "Success" || status === "Error") && mappedKey === "eraPlatformForm") {
        // CLose websocket and stop reconnection after process completes
        closeConnection();
        setPdfDownloadable(true);
        alert(t("output.alertDownload"));
        isDownloadableRef.current = true;
      }
    }
  }, [s_lastMessage])


  const renderStatusTables = () => {
    if (Object.keys(siteNames).length > 0 && Object.keys(siteDescriptions).length > 0) {
      return Object.keys(siteNames).map((key) => (
        <StatusTable key={key} siteName={siteNames[key]} siteFields={siteDescriptions[key]} siteStatus={siteStatuses[key]} setIsIdModalOpen={setIsIdModalOpen} />
      ));
    }
    return [];
  };

  return (
    <div className='white-background'>
      <div className='main-page-container'>
        <div className="output-header">
          <Header>
            <Icon name='home' />
            <Header.Content>
              {location.state.address.concat(" ID: ", location.state.case_id)}
            </Header.Content>
          </Header>
        </div>
        <Button onClick={goToCopilot} id="prev-button" animated="fade">
          <Button.Content visible><Icon name='plus' />{t("output.addCase")}</Button.Content>
          <Button.Content hidden>
            <Icon name='search plus' />
            <Icon name='home' />
          </Button.Content>
        </Button>
        <Label onClick={getPDF} as={pdfDownloadable ? "a" : "button"} color={pdfDownloadable ? "green" : "grey"} content={t("output.downloadPdf")} icon='download' id='download-pdf-btn' />
        <div className='tables-container'>
          {renderStatusTables()}
        </div>
      </div>

      {/* Pop up block navigation */}
      <ConfirmationModal 
        open={s_isPopUpOpen} 
        message={"A Background Process is Running... Please wait!"} 
        onConfirm={() => set_s_isPopUpOpen(false)} 
      />

      {/* Make a new case */}
      <ConfirmationModal 
        open={isModalOpen} 
        message={t("buttons.confirmLeave")} 
        onConfirm={handleConfirmLeave} 
        onCancel={handleCancelLeave} 
      />

      {s_ownerships.length &&
      <IDModal
        open={isIdModalOpen}
        s_ownerships={s_ownerships}
        message={t("certificateId.enterInfo") || "Enter Credentials"}
        handleSubmitID={handleSubmitID}
        setIsIdModalOpen={setIsIdModalOpen}
      />
      }
      
    </div>
  );
}

export default Output;
