import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "./reusable/ButtonComponent";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "./ui/dialog/dialog";
import { Input } from "./ui/input/input";
import { Label } from "./ui/label/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio/radio";
import validateTaiwanID from "../utils/validateTaiwanID";
import { orderColumns } from "@tanstack/react-table";

const IDModal = ({open, s_ownerships, message, handleSubmitID, setIsIdModalOpen, redYesButton}) => {
    // consts_ownerships = [
    //              {
    //                  "landID": "0238-0000", "totalOwners": "1", "ID": ["0010", "0011", "0012"], "IDType": "Registration Order"
    //              }
    //          ]
    const s_lands = s_ownerships.map((item) => item.landID)
    const s_totalOwners = s_ownerships.map((item) => parseInt(item.totalOwners))

    // Owenerships format from websocket
    // "Ownerships": [
    //          {
    //              "landID": "0238-0000", "totalOwners": "1", "ID": ["0010", "0011", "0012"], "IDType": "Registration Order"
    //          }
    //      ]
    // if (s_ownerships) {
    //     console.log(`OWNERSHIPS IN IDMODAL: ${JSON.stringify(s_ownerships, null, 2)}`)
    //     console.log(`s_lands : ${s_lands}, s_totalOwners: ${s_totalOwners}`)
    // }
    const { t } = useTranslation();
    const [ s_inputType, set_s_inputType ] = useState("certificateID");
    const [certificateID, setCertificateID] = useState(
        s_lands.map((_, index) => Array(s_totalOwners[index]).fill(""))
    );
    const [registrationOrders, setRegistrationOrders] = useState(
        s_lands.map((_, index) => Array(s_totalOwners[index]).fill(""))
    );
    const [s_isValid, set_s_isValid] = useState(false);


    const handleCertificateIDChange = (landIndex, ownerIndex, value) => {
        const updatedCertificateID = [...certificateID]
        updatedCertificateID[landIndex][ownerIndex] = value
        setCertificateID(updatedCertificateID)
    }

    // Handle input change for the Registration Order array
    const handleRegistrationOrderChange = (landIndex, registrationIndex, value) => {
        const updatedOrders = [...registrationOrders];
        updatedOrders[landIndex][registrationIndex] = value
        setRegistrationOrders(updatedOrders);
    };

    const handleSubmit = () => {
        if (s_inputType === "certificateID") {
            const hasEmpty = certificateID.some((land) => land.some((id) => id.trim() === ""))
            if (hasEmpty) {
                alert(t("certificateId.emptyCertificateId"));
                return;
            }
            handleSubmitID("certificateID", s_lands, certificateID)
        } else if (s_inputType === "registrationOrder") {
            const hasEmpty = registrationOrders.some((order) => order.some((id) => id.trim() === ""))
            if (hasEmpty) {
                alert(t("certificateId.emptyRegistrationOrder"));
                return;
            }
            handleSubmitID("registrationOrder", s_lands, registrationOrders)
        }
    };

    const onCancel = () => {
        setIsIdModalOpen(false)
    }

    // Check Taiwan ID Validity
    useEffect(() => {
        console.log("inputtype", s_inputType)
        if (s_inputType === "certificateID") {
            const allValid = certificateID.every((land) => land.every((id) => id.trim() !== "" && validateTaiwanID(id)))
            set_s_isValid(allValid)
        } else if (s_inputType === "registrationOrder") {
            const allValid = registrationOrders.every((orders) => orders.every((order) => 
                typeof order === "string" &&  /^[0-9]{4}$/.test(order.trim())
            ));
            set_s_isValid(allValid);
        } else {
            const allFilled = certificateID.every((order) => order.every((id) => id.trim() !== ""))
            set_s_isValid(allFilled)
        }
    }, [certificateID, registrationOrders, s_inputType])

    return (
        <Dialog open={open} onOpenChange={setIsIdModalOpen}>
            <DialogContent className="w-[500px]">
                <DialogHeader className="flex justify-center mb-6">
                    <DialogTitle className="text-black font-bold">{message}</DialogTitle>
                </DialogHeader>
                <div className="space-y-4">
                <RadioGroup value={s_inputType} onValueChange={set_s_inputType} className="flex space-x-4">
                    <RadioGroupItem value="certificateID" />
                    <Label>{t("certificateId.radioCertificateID") || "Certificate ID"}</Label>
                    <RadioGroupItem value="registrationOrder"/>
                    <Label>{t("certificateId.radioRegistrationOrder") || "Registration Order"}</Label>
                </RadioGroup>

                {s_lands.map((land, index) => (
                    <div key={land} className="space-y-2 mt-4">
                        <Label className="text-lg font-bold ml-2">
                            {t("certificateId.landLabel") || "Land ID"}: {land}
                        </Label>
                        <div className="grid grid-cols-2 gap-4">
                            {Array.from({ length: s_totalOwners[index] }).map((_, ownerIndex) => {
                                const isInputValid = s_inputType === "certificateID" ? validateTaiwanID(certificateID[index][ownerIndex]) : /^[0-9]{4,5}$/.test(registrationOrders[index][ownerIndex])
                                return (
                                    <div key={ownerIndex}>
                                        <Input
                                            placeholder={
                                            s_inputType === "certificateID"
                                                ? `${t("certificateId.certificateIdPlaceholder") || "Enter Certificate ID"}`
                                                : `${t("certificateId.registrationOrderPlaceholder") || "Enter Registration Order"}`
                                            }
                                            value={
                                            s_inputType === "certificateID"
                                                ? certificateID[index][ownerIndex]
                                                : registrationOrders[index][ownerIndex]
                                            }
                                            onChange={(e) =>
                                            s_inputType === "certificateID"
                                                ? handleCertificateIDChange(index, ownerIndex, e.target.value)
                                                : handleRegistrationOrderChange(index, ownerIndex, e.target.value)
                                            }
                                        />

                                        {s_inputType === "certificateID" && !isInputValid && certificateID[index][ownerIndex] && (
                                            <p className="text-red-500 text-xs">
                                                {t("certificateId.invalidCertificateId") || "Invalid Taiwan ID"}
                                            </p>
                                        )}

                                        {s_inputType === "registrationOrder" && !isInputValid && registrationOrders[index][ownerIndex] && (
                                            <p className="text-red-500 text-xs">
                                                {t("certificateId.invalidRegistrationOrder") || "Invalid Registration Order"}
                                            </p>
                                        )}
                                    </div>
                                )
                            }
                            
                            )}
                        </div>
                    </div>
                ))}
                </div>
                <DialogFooter className="mt-4">
                    <CustomButton
                        variant={redYesButton ? "destructive" : "primary"}
                        onClick={handleSubmit}
                        className="mr-2"
                        disabled={!s_isValid}
                    >
                        {t("certificateId.submitButton")}
                    </CustomButton>
                    <CustomButton variant="default" onClick={onCancel}>
                        {t("buttons.no")}
                    </CustomButton>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default IDModal