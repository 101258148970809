import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { CreditsProvider } from './context/CreditsProvider';
import routes from './routes';
import './App.css'
import { NavigationBlockerProvider } from './context/NavigationBlockerContext';

const App = () => {
  return (
    <Router>
      <div className="App">
       <Routes>
        {routes.map((route, index) => (
          route.children ? (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            >
              {route.children.map((child, childIndex) => (
                <Route
                  key={childIndex}
                  path={child.path}
                  element={child.element}
                />
              ))}
            </Route>
          ) : (
            <Route 
              key={index}
              path={route.path}
              element={route.element}
            />
          )
        ))}
       </Routes>
      </div>
    </Router>
  );
};

const WrappedApp = () => (
    <AuthProvider>
      <NavigationBlockerProvider>
        <CreditsProvider>
          <App />
        </CreditsProvider>
      </NavigationBlockerProvider>
    </AuthProvider>
);

export default WrappedApp;
