import CustomButton from "./reusable/ButtonComponent";
import { Player } from "@lottiefiles/react-lottie-player";

const successAnimation = "/doneanimation.json"
const errorAnimation = "/sadanimation.json"


const ScrapingResultModal = ({ open, title, description, isSuccess, showIcon, onClose }) => {
    if (!open) return null; // Don't render if not open

    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-center items-center w-[528px] h-min-[190px] h-max-[352px]">
                {/* Title */}
                <div className="flex flex-col gap-2 w-full items-center">
                    <h2 className="text-3xl font-bold">{title}</h2>

                    {description && (
                        <p className="text-md text-gray-600 mt-2">{description}</p>
                    )}
                </div>

                <Player />


                {/* Icon */}
                {showIcon && (
                    <Player
                        autoplay
                        loop={true} // Play only once
                        src={isSuccess ? successAnimation : errorAnimation}
                        style={{ width: isSuccess ? "300px" : "200px", height: isSuccess ? "300px" : "200px", marginTop: isSuccess ? "" : "2rem", marginBottom: isSuccess ? "" : "2rem" }}
                    />
                )}

                {/* OK Button */}
                <CustomButton 
                    variant="secondary" 
                    onClick={onClose} 
                    className="mt-6 w-[120px] h-[43px] text-xl"
                >
                    關閉
                </CustomButton>
            </div>
        </div>
    );
};

export default ScrapingResultModal;
