import { useTranslation } from 'react-i18next';
import { Button } from './ui/button/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog/dialog';

const ConfirmationModal = ({ open, title, message, onConfirm, onCancel, redYesButton, messageClassName = "", yesButton, noButton }) => {
  const {t} = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onCancel} className="pt-6">
      <DialogContent className='text-center space-y-4'>
        {title && (
          <DialogHeader>
            <DialogTitle className="text-lg font-bold text-gray-900">{title}</DialogTitle>
          </DialogHeader>
        )}
        <div className='p-4'>
          <p className={`${messageClassName ?? "text-lg text-gray-800"}`}>{message}</p>
        </div>

        <DialogFooter className='flex justify-center space-x-4'>
          {onCancel && (
            <Button variant='outline' onClick={onCancel}>
              {noButton ?? t("buttons.no")}
            </Button>
          )}
          <Button 
            className={redYesButton ? "bg-red-500 hover:bg-red-600 text-white" : "bg-gray-700 hover:bg-gray-800 text-white"} 
            onClick={onConfirm}
          >
            {yesButton ?? t("buttons.yes")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
