import { debounce } from 'lodash';
import { Home } from "lucide-react";
import { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCaseDetails } from '../apis/cases/casesApi';
import { getPDF } from '../apis/script/scriptApi';
import ConfirmationModal from '../components/confirmationModal';
import DownloadPartialModal from '../components/downloadPartialModal';
import IDModal from '../components/idModal';
import NavigationBlocker from '../components/navigationBlocker';
import CustomButton from '../components/reusable/ButtonComponent';
import ToastComponent from '../components/reusable/ToastComponent';
import StatusTable from '../components/statusTable';
import Spinner from '../components/ui/spinner/spinner';
import { AuthContext } from '../context/AuthProvider';
import { useCredits } from '../context/CreditsProvider';
import { useNavigationBlockerContext } from '../context/NavigationBlockerContext';
import useAPI from '../hooks/useAPI';
import useNavigationBlocker from '../hooks/useNavigationBlocker';
import useWebsocket from '../hooks/useWebsocket';
import ScrapingResultModal from '../components/popUpModal';

var fileDownload = require('js-file-download');


const default_status = {
    easymap: "Waiting",
    illegalConstruction: "Waiting",
    vicinityMap: "Waiting",
    schoolDistrict: "Waiting",
    googleMaps: "Waiting",
    roadWidth: "Waiting",
    registryInfo: "Waiting",
    zoningMap: "Waiting",
    taxEstimation: "Waiting",
    userPermit: "Waiting",
    eraPlatformForm: "Waiting"
};

const siteNameMap = {
    "easymap" : "easymap",
    "illegal_construction": "illegalConstruction",
    "vicinity_map": "vicinityMap",
    "school_district": "schoolDistrict",
    "nearby_landmarks": "googleMaps",
    "road_width": "roadWidth",
    "registry_info": "registryInfo",
    "zoning_map": "zoningMap",
    "tax_estimation": "taxEstimation",
    "user_permit": "userPermit",
    "era_form": "eraPlatformForm",
};

// For Showing IDModal on Development
// const mockOwnership = [
//         {
//             "landID": "0238-0000", "totalOwners": "1", 
//             // "ID": ["0010", "0011", "0012"], "IDType": "Registration Order"
//         }
//     ]

const CaseDetail = () => {
    const { caseID } = useParams();
    const { t } = useTranslation();
    const { auth } = useContext(AuthContext)
    const { s_credits, call_getUserCredits } = useCredits();

    
    const { state, dispatch } = useNavigationBlockerContext();
    const { attemptNavigation } = useNavigationBlocker();
    
    const [s_dataType, set_s_dataType] = useState(null);
    const [s_caseData, set_s_caseData] = useState({});
    const [s_ownerships, set_s_ownerships] = useState([])
    const [s_loading, set_s_loading] = useState(true);
    const [s_pdfDownloadable, set_s_pdfDownloadable] = useState(false);
    const [s_toastMessage, set_s_toastMessage] = useState(null);
    const [s_isToastShowing, set_s_isToastShowing] = useState(false);
    const [s_modalMessage, set_s_modalMessage] = useState(null);
    const [s_scrapingDoneModalOpen, set_s_scrapingDoneModalOpen] = useState(false);
    const [s_isModalOpen, set_s_isModalOpen] = useState(false);
    const [s_showNoCreditModal, set_s_showNoCreditModal] = useState(false);
    const [s_downloadButtonDisabled, set_s_downloadButtonDisabled] = useState(true);
    const [s_errorMessage, set_s_errorMessage] = useState(null);
    const [s_successMessage, set_s_successMessage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [s_isPopUpOpen, set_s_isPopUpOpen] = useState(false);
    const [isIdModalOpen, setIsIdModalOpen] = useState(false);
    const [siteStatuses, setSiteStatuses] = useState(default_status);
    const [s_registrySuccess, set_s_registrySuccess] = useState(false);
    const [s_successfulWebsite, set_s_successfulWebsite] = useState(null);

    const statusMap = useRef({...default_status})

    const shouldConnectWebSocket = s_dataType !== "output";
    const { s_isConnected, sendMessage, s_lastMessage, closeConnection, s_error } = useWebsocket(shouldConnectWebSocket)
    
    const call_getCaseDetails = useAPI(getCaseDetails);
    const call_getPDF = useAPI(getPDF, true)
    
    // console.log(`ITEM ID:`, caseID);
    
    const goToCopilot = () => {
        const isRunning = s_caseData.status === "Pending"
        // console.log(`Background Process : ${isRunning}`)
        if (isRunning){
        set_s_isPopUpOpen(true);
        } else {
        setIsModalOpen(true);
        }
    };

    const handleGetPDF = () => {
        if (s_downloadButtonDisabled) return; 

        if (s_credits === 0) {
            set_s_showNoCreditModal(true)
        } else {
            set_s_loading(true)
            call_getPDF.request({ caseName: s_caseData.title, caseID: s_caseData.id, userID: auth.id })
        }
    }

    const handleSubmitID = (inputType, lands, inputValue) => {
        const payload = {
            case_id: s_caseData.id,
            case_name: s_caseData.title,
            input_data: {
            input_type : inputType,
                values : lands.map((item, index) => {
                return {
                    landId: item,
                    id : inputValue[index]
                }
                })
            },
            site_name: "registry_info",
            timestamp: new Date().toISOString(),
        }
        sendMessage(payload)
        set_s_ownerships([])

        setIsIdModalOpen(false)
    }

    const debounceSetOwnerships = useCallback(debounce((ownerships) => {
        set_s_ownerships(ownerships);
    }, 300), []);


    const debounceUpdateStatus = useCallback(debounce((updateStatuses) => {
        setSiteStatuses(updateStatuses);
    }, 300), []);


    useEffect(() => {
        set_s_loading(true)
        setTimeout(() => {
            call_getCaseDetails.request(caseID) // Delay for case to be created
        }, 4000);
    }, [])

    useEffect(() => {
        if (call_getCaseDetails.status === "suc") {
            set_s_caseData(call_getCaseDetails.data)
            set_s_successMessage(t("output.getSuccess"))
        } else if (call_getCaseDetails.status === "err"){
            set_s_errorMessage(t("output.getError"))
            console.error(`Error getting data: ${call_getCaseDetails.msg}`)
        }
    }, [call_getCaseDetails.status])

    // If error getting the case show toast and refresh the page
    // Might be a problem at the backend when trying to create the case
    useEffect(() => {
        if (s_errorMessage) {
            setTimeout(() => {
                call_getCaseDetails.request(caseID) // Try again in two seconds
            }, 2000);
        }
    }, [s_errorMessage])

    useEffect(() => {
        // console.log("s_casedata", s_caseData)
        if(s_caseData.status === "Error" || s_caseData.status === "Done"){
            set_s_loading(false)
            set_s_dataType("detail")
            
            let failedWebsites = [];
            let successfulWebsites = [];
            // Conditions for pdf to not be downloadable
            let isEasymapSuccess = false;
            let registryInfoSuccess = false;

            // Required websites for deciding PDF eligibility
            let websiteStatuses = {
                illegal_construction: false,
                vicinity_map: false,
                road_width: false,
                school_district: false,
            };

            // Websites that depend on registry_info
            let registryDependentWebsites = {
                tax_estimation: false,
                user_permit: false,
            };

            s_caseData.website_status.forEach(({status, website}) => {
                if (website === "easymap") {
                    isEasymapSuccess = status === "Success";
                }
                if (website === "registry_info") {
                    registryInfoSuccess = status === "Success";
                }
                if (website in websiteStatuses) {
                    websiteStatuses[website] = status === "Success" || status === "otherInformation";
                }
                if (website in registryDependentWebsites) {
                    registryDependentWebsites[website] = status === "Success";
                }
    
                if (status !== "Success") {
                    if (status !== "otherInformation"){
                        failedWebsites.push(website)
                    }
                }
            })

            const successCount = Object.values(websiteStatuses).filter(success => success).length;

            // If registry_info is successful, check its dependent websites
            if (registryInfoSuccess) {
                set_s_registrySuccess(true)
                Object.entries(registryDependentWebsites).forEach(([website, status]) => {
                    if (status) {
                        successfulWebsites.push(website); // Add dependent websites
                    }
                });
            }

            // Push only downloadable websites to successfulWebsites
            Object.entries(websiteStatuses).forEach(([website, status]) => {
                if (status) successfulWebsites.push(website);
            });
    
            if (registryInfoSuccess) {
                successfulWebsites.push("registry_info"); // Add registry_info itself if successful
            }

            set_s_successfulWebsite(successfulWebsites)

            // console.log("Easymap Success:", isEasymapSuccess);
            // console.log("Registry Info Success:", registryInfoSuccess);
            // console.log("Success Count:", successCount);
            // console.log("Failed Websites:", failedWebsites);
            // console.log("Successful Websites (Downloadable):", successfulWebsites);

            // --- Conditions for setting toast message and PDF availability ---
            if (!isEasymapSuccess) {
                set_s_toastMessage({
                    label: t("output.doneScrapingError"),
                    isSuccess: false
                });
                set_s_pdfDownloadable(false);
                return;
            }

            // console.log("failedwebsites", failedWebsites)
            if (failedWebsites.length === 1 && failedWebsites.includes("era_form")) {
                set_s_toastMessage({
                    label: t("output.doneScraping"),
                    desc: t("output.alertDownload"),
                    isSuccess: true
                });
                set_s_pdfDownloadable(true);
                return;
            }

            if (failedWebsites.length === 0) {
                // If all websites succeed, show `doneScraping`
                set_s_toastMessage({
                    label: t("output.doneScraping"),
                    desc: t("output.alertDownload"),
                    isSuccess: true
                });
                set_s_pdfDownloadable(true);
                return;
            }

            if (!registryInfoSuccess && successCount < 2) {
                // `registry_info` failed and fewer than 2 success
                set_s_toastMessage({
                    label: t("output.doneScrapingError"),
                    isSuccess: false
                });
                set_s_pdfDownloadable(false);
                return;
            }

            // Otherwise, `doneScrapingWithError`
            set_s_toastMessage({
                label: t("output.doneScrapingWithError"),
                desc: t("output.alertPartialDownload"),
                isSuccess: false
            });
            set_s_pdfDownloadable(true);

        } else if (s_caseData.status === "Pending") {
            set_s_loading(false)
            set_s_dataType("output")
        }
    }, [s_caseData])

    
    useEffect(() => {
        console.log("pdfdata",call_getPDF.data)
        console.log("pdfstatus",call_getPDF.status)
        if (call_getPDF.status === "suc"){
            // console.log("pdfdata",call_getPDF.data); // Debug Blob data
            set_s_isModalOpen(false); // Close modal
            fileDownload(call_getPDF.data, `${s_caseData.title}.pdf`);
            set_s_loading(false)
            call_getUserCredits.request(auth.id) // refresh credits
        } else if (call_getPDF.status === "err"){
            console.log(`Error fetching pdf`, call_getPDF.msg)
            set_s_isModalOpen(false)
            set_s_toastMessage({
                label: t("output.pdfError"),
                isSuccess: false
            })
        }
    }, [call_getPDF.status])

    useEffect(() => {
        set_s_downloadButtonDisabled(!s_pdfDownloadable)
    }, [s_pdfDownloadable])

    useEffect(() => {
        if (s_lastMessage && s_lastMessage.case_number === caseID) {
            // console.log("Last Message from WS:", s_lastMessage);
            const { case_number, site_name, status, ownerships } = s_lastMessage;
            const mappedKey = siteNameMap[site_name] || site_name;

            if (status === "noOtherRights" && ownerships) {
                debounceSetOwnerships(ownerships)
            }

            if (mappedKey in default_status) {
                statusMap.current[mappedKey] = status
                debounceUpdateStatus({...statusMap.current})
            }

            // if (status === "errorFinal" || status === "wrongInput") {
            //     closeConnection();
            //     set_s_toastMessage({
            //         label: t("output.doneScrapingWithError"),
            //         desc: t("output.alertPartialDownload")
            //     })

            //     setTimeout(() => {
            //         call_getCaseDetails.request(caseID)
            //     }, 3000);
            // }

            // If easymap fails
            // if (status === "errorFinal" && mappedKey === "easymap") {
            //     closeConnection();
            //     set_s_modalMessage({
            //         label: t("output.doneErrorModalTitle"),
            //         desc: t ('output.doneErrorModalDesc'),
            //         isSuccess: false,
            //         showIcon: true
            //     })
            // }

            if ((status === "Success" || status === "Error" || status === "Skipped" || status === "errorFinal"|| status === "errorSkipped") 
                && mappedKey === "eraPlatformForm") 
            {
                closeConnection();
                if (status === "Skipped" || status === "Error" || status === "errorFinal" || status === "errorSkipped") {
                    set_s_modalMessage({
                        label: t("output.donePartialModalTitle"),
                        desc: t("output.donePartialModalDesc"),
                        isSuccess: false,
                        showIcon: false
                    })
                } else {
                    set_s_modalMessage({
                        label: t("output.doneModalTitle"),
                        desc: t("output.doneModalDesc"),
                        isSuccess: true,
                        showIcon: true
                    })
                }
            }
        }
    }, [s_lastMessage])

    useEffect(() => {
        // console.log("Data type:", s_dataType)
        if (s_caseData.website_status) {
            // console.log("s_caseData", s_caseData)
            // console.log("s_caseData.website_status", s_caseData.website_status)
            s_caseData.website_status.forEach((item) => {
                const siteName = siteNameMap[item.website]
                const ownerships = item.ownerships
                const status = item.status
                setSiteStatuses((prevState) => ({
                    ...prevState,
                    [siteName]: item.status,
                }));
                statusMap.current[siteName] = item.status

                if (ownerships && status === "noOtherRights") {
                    set_s_ownerships(ownerships)
                }
            })
        }
    }, [s_dataType, s_caseData])

    useEffect(() => {
        console.log("DATA TYPE:", s_dataType)
        if (s_dataType === "detail"){
            dispatch({ type: "NOT_BLOCKED" })
            console.log("CLOSING WEBSOCKET")
            closeConnection()
        } else if (s_dataType === "output"){
            dispatch({ type: "BLOCK" }); // Block Navigation From output page
        }
    }, [s_dataType])

    // Show toast if data type is detail(list) and show modal if data type is output(scraping process is done)
    useEffect(() => {
        // console.log("s_toastMessage", s_toastMessage)
        if (s_toastMessage){
            set_s_isToastShowing(true)
        }
    }, [s_toastMessage])

    // Showing Modal after scraping is done or error
    useEffect(() => {
        if (s_modalMessage) {
            set_s_scrapingDoneModalOpen(true)
        }
    }, [s_modalMessage])

    // Browser Tab title notification
    useEffect(() => {
        let interval;
        const originalTitle = document.title;
        if (s_scrapingDoneModalOpen && s_modalMessage && s_dataType === "output" && document.hidden) {
            let toggle = false;

            interval = setInterval(() => {
                document.title = toggle ? `✅ ${s_modalMessage.label}!` : originalTitle;
                toggle = !toggle;
            }, 1000)
        } else {
            document.title = originalTitle;
        }

        return () => {
            clearInterval(interval)
            document.title = originalTitle;
        }
    }, [s_scrapingDoneModalOpen, s_modalMessage])

    useEffect(() => {
        if (call_getUserCredits.status === "suc") {
            call_getCaseDetails.request(caseID)
        } else if (call_getUserCredits.status === "err") {
            console.error("Error refreshing credits")
        }
    }, [call_getUserCredits.status])

    useEffect(() => {
        if (state.isBlocked) {
            // console.log("🔒 Navigation is currently blocked.");
        }
    }, [state.isBlocked]);

    // OPEN INPUT MODAL FOR 身份證 or 登記次序
    useEffect(() => {
        if (s_ownerships.length > 0) {
            setIsIdModalOpen(true);
        }
    }, [s_ownerships])

    // useEffect(() => {
    //     if (s_successfulWebsite) {
    //         console.log("s_successfulWebsite", s_successfulWebsite);
    //     }
    // }, [s_successfulWebsite]);
    
    if (s_loading) return (
        <Spinner />
    )

    return (
        <div className="flex flex-col align-center items-center p-8">
            <NavigationBlocker />
            <div className='flex flex-col w-full px-24 items-center'>
                <div className="flex flex-col m-4 justify-center text-center items-center gap-2">
                    <h1 className='text-2xl font-bold flex align-center items-center'>{s_caseData.title}</h1>
                    <h1 className="text-2xl font-bold flex align-center">
                        <Home className="w-8 h-8 mr-2" />{s_caseData.address} (ID: {s_caseData.id})
                    </h1>
                    {s_dataType === "output" && (
                        <p className='text-xl font-md'>{t("output.onProgress")}</p>
                    )}
                    {s_pdfDownloadable && s_successfulWebsite && s_successfulWebsite.length !== 7 && (
                        <div className='flex flex-col w-full'>
                            <p className='text-red-500 text-md mt-2'>{t("output.downloadPdfPartialDesc")}</p>
                            {s_successfulWebsite.length > 0 && (
                                <p className="text-red-500 text-md font-bold">
                                    ({s_successfulWebsite.map(website => t(`siteNames.${siteNameMap[website] || website}`)).join(", ")})
                                </p>
                            )}
                        </div>
                    )}
                </div>
        
                {/* <CustomButton variant="primary" onClick={goToCopilot} className="mb-6 w-[200px]">
                    {t("output.addCase")}
                </CustomButton> */}
    
                {s_pdfDownloadable && (
                    <CustomButton
                        variant={s_pdfDownloadable ? "success" : "default"}
                        onClick={() => {
                            set_s_isModalOpen(true)
                            set_s_isToastShowing(false)
                            set_s_toastMessage(null)
                        }}
                        disabled={s_downloadButtonDisabled}
                        className="mb-6 w-[200px]"
                    >
                        {s_successfulWebsite.length !== 7 || !s_registrySuccess ? t("output.downloadPdfPartial") : t("output.downloadPdf")}
                    </CustomButton>
                )}
        
                <div className="space-y-6 w-full mt-8">
                    {s_caseData.website_status.map((item, index) => {
                        const siteName = siteNameMap[item.website];
                        return (
                        <StatusTable
                            key={index}
                            siteName={t(`siteNames.${siteName}`)}
                            siteFields={t(`siteDescriptions.${siteName}`)}
                            siteStatus={siteStatuses[siteName]}
                            setIsIdModalOpen={setIsIdModalOpen}
                        />
                        );
                    })}
                </div>
        
                {/* Background Process is Running Modal */}
                <ConfirmationModal
                    open={s_isPopUpOpen}
                    message={t("home.backgroundProcess")}
                    onConfirm={() => set_s_isPopUpOpen(false)}
                    onCancel={() => set_s_isPopUpOpen(false)}
                />
        
                {/* Leave This Page Modal */}
                <ConfirmationModal
                    open={isModalOpen}
                    message={t("buttons.confirmLeave")}
                    onConfirm={() => attemptNavigation("/copilot")}
                    onCancel={() => setIsModalOpen(false)}
                />

                {/* No Credit Available To Download PDF Modal */}
                <ConfirmationModal
                    open={s_showNoCreditModal}
                    message={t("caseDetail.noCredit")}
                    onConfirm={() => {
                        set_s_showNoCreditModal(false)
                        set_s_isModalOpen(false)
                    }}
                    onCancel={() => set_s_showNoCreditModal(false)}
                />
                
                {/* Error Modal */}
                {/* <ConfirmationModal
                    open={s_errorModalOpen}
                    message={s_errorMessage}
                    onConfirm={() => {
                        set_s_errorModalOpen(false)
                        set_s_errorMessage(null)
                    }}
                    onCancel={() => {
                        set_s_errorModalOpen(false)
                        set_s_errorMessage(null)
                    }}
                    messageClassName='text-xl font-bold text-red-500'
                /> */}
        
                {s_ownerships.length > 0 && (
                    <IDModal
                        open={isIdModalOpen}
                        s_ownerships={s_ownerships}
                        message={t("certificateId.enterInfo")}
                        handleSubmitID={handleSubmitID}
                        setIsIdModalOpen={setIsIdModalOpen}
                    />
                )}
        
                {s_isToastShowing && (
                    <ToastComponent
                        title={s_toastMessage.label}
                        description={s_toastMessage.desc}
                        variant={s_toastMessage.isSuccess ? "success": "error"}
                        duration={s_toastMessage.isSuccess ?? 3000}
                        position="top-center"
                    />
                )}

                {s_successfulWebsite && s_successfulWebsite.length !== 7 && s_pdfDownloadable && s_successfulWebsite && s_successfulWebsite.length > 2 
                ? (
                    <DownloadPartialModal 
                        open={s_isModalOpen}
                        title={t("caseDetail.downloadPdfPartialModalTitle")}
                        message={s_caseData.downloaded === true ? t("caseDetail.downloadPdfPartialModalDesc") : t("caseDetail.downloadPdfPartialModalDescCharged")}
                        onConfirm={handleGetPDF}
                        websites={s_successfulWebsite}
                        onCancel={() => set_s_isModalOpen(false)}
                    />
                ) : (
                    <ConfirmationModal 
                        open={s_isModalOpen}
                        message={s_caseData.downloaded === true ? t("caseDetail.downloadPDF") : t("caseDetail.downloadPDFCharged")}
                        onConfirm={handleGetPDF}
                        onCancel={() => set_s_isModalOpen(false)}
                    />
                )}

                <ScrapingResultModal
                    open={s_scrapingDoneModalOpen}
                    title={s_modalMessage?.label}
                    description={s_modalMessage?.desc}
                    isSuccess={s_modalMessage?.isSuccess}
                    showIcon={s_modalMessage?.showIcon}
                    onClose={() => {
                        set_s_modalMessage(null)
                        set_s_scrapingDoneModalOpen(false);
                        call_getCaseDetails.request(caseID);
                    }}
                />

                {/* Error Toast */}
                {s_errorMessage && (
                    <ToastComponent
                        title={s_errorMessage}
                        variant={"error"}
                        position="top-center"
                    />
                )}
                {s_successMessage && s_dataType === "output" && (
                    <ToastComponent
                        title={s_successMessage}
                        variant={"success"}
                        duration={2000}
                        position="top-center"
                    />
                )}
                
            </div>
        </div>
)}

export default CaseDetail
