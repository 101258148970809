import { useCallback, useEffect, useRef, useState } from "react"

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

const useWebsocket = (shouldReconnect = true) => {
    const [s_isConnected, set_s_isConnected] = useState(false);
    const [s_lastMessage, set_s_lastMessage] = useState(null);
    const [s_error, set_s_error] = useState(null);
    const [s_reconnect, set_s_reconnect] = useState(shouldReconnect);

    const socketRef = useRef(null);
    const MAX_RETRIES = 5;
    const retryCountRef = useRef(0);

    const connect = useCallback(() => {
        if (!shouldReconnect || socketRef.current) return // Prevent reconnection

        // Initialize Websocket connection
        const ws = new WebSocket(WEBSOCKET_URL);
        socketRef.current = ws;

        // Handle the Websocket open event
        ws.onopen = () => {
            console.log("Websocket connection opened");
            set_s_isConnected(true);
            retryCountRef.current = 0;
            set_s_error(null)
        }

        // Handle incoming messages
        ws.onmessage = (event) => {
            try{
                const parsedData = JSON.parse(event.data)
                set_s_lastMessage(parsedData)
            } catch(parseError){
                console.error("Failed to parse Websocket message:", parseError)
            }
        };
    
        ws.onclose = () => {
            console.log("WebSocket connection closed");
            set_s_isConnected(false);
            socketRef.current = null; 

            // Attempt to reconnect, but limit retries
            if (shouldReconnect  && retryCountRef.current < MAX_RETRIES) {
                retryCountRef.current += 1;
                setTimeout(() => {
                connect();
                }, 1000 * retryCountRef.current); // Increase delay with each retry
            } else {
                console.warn("Max reconnection attempts reached.");
                set_s_error("Could not connect to WebSocket.");
            }
        };

        ws.onerror = (err) => {
            console.error("WebSocket error:", err);
            set_s_error(err.message || "WebSocket error occurred.");
        };
    }, [shouldReconnect])

    useEffect(() => {
        if (shouldReconnect) {
            connect();
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [shouldReconnect]);

    // To send message from frontend to backend
    const sendMessage = useCallback((message) => {
        console.log("Sending Message from frontend to ws:", message)
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN){
            socketRef.current.send(JSON.stringify(message));
            console.log("Message sent to Websocket")
        } else {
            console.warn("Websocket is not open. Message not sent:", message);
        }
    }, [])

    // Close ws connection and not reopen until useWebSocket is called again.
    const closeConnection = useCallback(() => {
        set_s_reconnect(false)
        if(socketRef.current){
            socketRef.current.close()
        }
    }, [])

    return {s_isConnected, s_lastMessage, sendMessage, closeConnection, s_error}
}

export default useWebsocket