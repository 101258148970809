import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAccount } from '../apis/account/accountApi';
import { getCompanyDetail } from '../apis/company/companyApi';
import { createNewCase, getBackgroundProcessStatus, runScrapingScript } from '../apis/script/scriptApi';
import { getUserDetails } from '../apis/users/usersAPi';
import { AuthContext } from '../context/AuthProvider';
import useAPI from '../hooks/useAPI';
import validateTaiwanID from '../utils/validateTaiwanID';
import ConfirmationModal from './confirmationModal';
import NoAccountModal from './noAccountModal';
import CustomButton from './reusable/ButtonComponent';
import { Loader } from './reusable/LoaderComponent';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from './ui/card/card';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from './ui/form/form';
import { Input } from './ui/input/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select/select';
import { Separator } from './ui/separator/separator';
import Spinner from './ui/spinner/spinner';

const CopilotInputFrame = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext)
  const [s_caseID, set_s_caseID] = useState(null);
  const [s_formData, set_s_formData] = useState(null);
  const [s_registryAccount, set_s_registryAccount] = useState(null);
  const [s_eraAccount, set_s_eraAccount] = useState(null);
  const [s_error, set_s_error] = useState(null);
  const [s_isLoading, set_s_isLoading] = useState(false);
  const [s_noAccountModal, set_s_noAccountModal] = useState(false);
  const [s_getCaseIDPayload, set_s_getCaseIDPayload] = useState(null);
  const [s_isModalOpen, set_s_isModalOpen] = useState(false); // Background Process Modal
  const [s_websiteWithAccountLength, set_s_websiteWithAccountLength] = useState(0); // How many websites have account per company

  const [s_liveAddress, set_s_liveAddress] = useState("");
  const [s_showPreview, set_s_showPreview] = useState(false);

  // console.log(validateTaiwanID("F160877809"))

  const call_getAccount = useAPI(getAccount)
  const call_userDetails = useAPI(getUserDetails)
  const call_createNewCase = useAPI(createNewCase)
  const call_runScrapingScript = useAPI(runScrapingScript)
  const call_getBackgroundProcessStatus = useAPI(getBackgroundProcessStatus)
  const call_getCompanyDetail = useAPI(getCompanyDetail)

  const form = useForm({
    mode: "onBlur", 
    reValidateMode: "onChange", 
    defaultValues: {
      city: "臺北", // Always Taipei
      district: "",
      lane: "",
      alley: "",
      village: "",
      streetNumber: "",
      streetType: "路", // Default to "街"
      section: "",
      floor: "",
      floorType: "樓",
      floorSection: "",
      number: "",
      caseName: '',
      userID: auth.id,
      ownerID: [],
      percentage: [],
      registryInfo: 'no',
      uploadFile: null,
    }
  })

  const watchedAddress = useWatch({ control: form.control });
  const floorType = useWatch({ control: form.control, name: "floorType"})
  const watchedOwnerIDs = useWatch({
    control: form.control,
    name: "ownerID", // this returns an array of { id }
  });

  const {
    fields: ownerFields,
    append: appendOwner,
    remove: removeOwner,
  } = useFieldArray({
    control: form.control,
    name: "ownerID",
  });
  
  const {
    fields: percentageFields,
    append: appendPercentage,
    remove: removePercentage,
  } = useFieldArray({
    control: form.control,
    name: "percentage",
  });
  

  // Required fields for address
  const requiredFields = [
    "city",
    "district",
    "streetNumber",
    "streetType",
    "number"
  ];

  // Blocker for adding percentage
  const canAddPercentage = watchedOwnerIDs.some(
    (owner, index) => owner?.id?.trim() && !form.getValues("percentage")?.[index]?.id?.trim()
  );

  // Check if all required fields are filled
  const isAddressComplete = requiredFields.every(
    (field) => watchedAddress[field] && watchedAddress[field].trim() !== ""
  );

  // Fetch case ID and navigate to the output page
  const getCaseID = (formData) => {
    set_s_isLoading(true); // Start loading state when requesting case ID
    const { address, caseName, registryUsername, registryPassword } = formData
    const payload = {
      address,
      case_name: caseName,
      user_id: auth.id
    } 
    call_createNewCase.request(payload)
    set_s_formData(formData); 
  };

  // Submit script request to the backend
  const submitScriptRequest = (formData) => {
    // console.log("formdata", formData)
    call_runScrapingScript.request(formData)
  };

  const constructAddress = (data) => {
    // console.log("data in constructAddress", data)
    let address = `${data.city}市${data.district}區`;
    address += `${data.streetNumber}${data.streetType}`;
    if (data.section) address += `${data.section}段`;
    if (data.village) address += `${data.village}里`;
    if (data.lane) address += `${data.lane}巷`;
    if (data.alley) address += `${data.alley}弄`;
    address += `${data.number}號`;
    if (data.floor) address += `${data.floor}${data.floorType}`;
    if (data.floorSection) address += `之${data.floorSection}`
    return address;
  };

  // Handle form submission
  const handleSubmit = (data) => {
    call_getBackgroundProcessStatus.request()
    // console.log('Form Data:', data)
    const fullAddress = constructAddress(data)
    // console.log("fullAddress", fullAddress)

    const cleanedData = {
      ...data,
      address: fullAddress
          .replace(/㇐/g, '一')  // Replace stroke character
          .replace(/𠘨/g, '西')  // Replace uncommon "西" variant
          .trim(),  // Remove leading/trailing spaces
    };

    // console.log("cleanedData", cleanedData)
    set_s_error(null);
    set_s_getCaseIDPayload(cleanedData)
  };

  const convertToFormData = (data, case_id) => {
    // console.log("Data:", data)
    const formDataPayload = new FormData();
    formDataPayload.append('address', data.address);
    formDataPayload.append('case_name', data.caseName);
    formDataPayload.append('user_id', data.userID);
    // formDataPayload.append('script_username', data.registryUsername);
    // formDataPayload.append('script_password', data.registryPassword);
    formDataPayload.append('case_id', case_id);
    formDataPayload.append('token', auth.accessToken)
    if (data.registryInfo === "no" && Array.isArray(data.ownerID)) {
      const extraArray = data.ownerID.map((owner, index) => {
        const ownerValue = owner.id?.trim();
        const percentage = data.percentage?.[index]?.id?.trim();

        if (!ownerValue) return null

        const dataType = validateTaiwanID(ownerValue)
          ? "Certificate ID"
          : /^[0-9]{4,5}$/.test(ownerValue)
            ? "registration order"
            : null;

        if (!dataType) return null;

        return {
          owner_id: ownerValue,
          data_type: dataType,
          percentage: percentage
        }
      }).filter(Boolean);

      if (extraArray.length > 0) {
        formDataPayload.append("extra", JSON.stringify(extraArray))
      }
    }
    if (data.registryInfo === 'yes' && data.uploadFile) {
      // console.log("HAS PDF")
      formDataPayload.append('registry_file', data.uploadFile[0]);
    }
    return formDataPayload;
  }

  const submitData = () => {
    try {
      const formDataPayload = convertToFormData(s_formData, s_caseID);
      submitScriptRequest(formDataPayload)
      // In the future i think it is best to just run the case in the backend when the case is created
      navigate(`/cases/${s_caseID}`) // Have to directly navigate or else it will create more than one case
    } catch (error) {
      set_s_error('Failed to submit script request')
      console.error('Error submitting script request:', error)
    }
  }

  const goToAccountPage = () => {
    set_s_noAccountModal(false)
    navigate("/account-settings/linked-accounts")
  }

  const getNoAccountMessage = () => {
    if (!s_registryAccount && !s_eraAccount) {
      return (
        <Trans i18nKey={"home.noAccount"}>
          You don't have linked accounts. Please go to<span className='hover:cursor-pointer text-blue-500 italic underline' onClick={() => navigate("/account-settings/linked-accounts")}>settings</span>and link your Registry account.
        </Trans>
      );
    } else if (!s_registryAccount) {
      return (
        <Trans i18nKey={"home.noRegistryAccount"}>
          You don't have a Registry account. Please go to<span className='hover:cursor-pointer text-blue-500 italic underline' onClick={() => navigate("/account-settings/linked-accounts")}>settings</span>and link your Registry account.
        </Trans>
      );
    } else if (!s_eraAccount) {
      return (
        <Trans i18nKey={"home.noEraAccount"}>
          You don't have a Era account. Please go to<span className='hover:cursor-pointer text-blue-500 italic underline' onClick={() => navigate("/account-settings/linked-accounts")}>settings</span>and link your Registry account.
        </Trans>
      );
    }
    return null; // Default case (shouldn't happen)
  };
  
  
  useEffect(() => {
    set_s_isLoading(true)
    call_userDetails.request();
    call_getAccount.request();
    call_getCompanyDetail.request()
  }, []);

  useEffect(() => {
    if (call_createNewCase.status === "suc"){
      set_s_caseID(call_createNewCase.data.case_id)
      set_s_isLoading(false)
    } else if (call_createNewCase.status === "err"){
      set_s_error(call_createNewCase.msg)
      set_s_isLoading(false)
    }
  }, [call_createNewCase.status])

  // All account that is needed for website scraping need to be linked
  useEffect(() => {
    if (call_getAccount.status === "suc" && call_getAccount.data){
        if (call_getAccount.data.length > 0){
          const registryAccount = call_getAccount.data.find((acc) => acc.account_name.includes("Registry Info_account"))
          const eraAccount = call_getAccount.data.find((acc) => acc.account_name.includes("ERA platform form_account"))
          set_s_registryAccount(registryAccount)
          set_s_eraAccount(eraAccount)
          set_s_isLoading(false)
        } else {
          set_s_isLoading(false)
        }
      } else if (call_getAccount.status === "err"){
        set_s_error(call_getAccount.msg)
      }
    }, [call_getAccount.status, s_websiteWithAccountLength])

  
  useEffect(() => {
    if (call_getCompanyDetail.status === "suc") {
      const accountWebsites = call_getCompanyDetail.data.websites.filter((site) => site.is_account);
      set_s_websiteWithAccountLength(accountWebsites.length)
    } else if (call_getCompanyDetail.status === "err") {
      console.error("Error fetching company data:", call_getCompanyDetail.msg);
    }
  }, [call_getCompanyDetail.status])

  useEffect(() => {
    if (s_caseID && s_formData) {
      submitData()
    }
  }, [s_caseID, s_formData])

  useEffect(() => {
    if (form.watch("registryInfo") === "yes") {
      form.setValue("ownerID", [])
    }
  }, [form.watch("registryInfo")])

  useEffect(() => {
    if (call_getBackgroundProcessStatus.status === "suc" && s_getCaseIDPayload){
      const isRunning = call_getBackgroundProcessStatus.data.is_running
      if (isRunning){
        set_s_isModalOpen(true)
      } else {
        getCaseID(s_getCaseIDPayload);
      }
    }
  }, [call_getBackgroundProcessStatus, s_getCaseIDPayload])

  useEffect(() => {
    // console.log("s_registryAccount", s_registryAccount)
    // console.log("s_eraAccount", s_eraAccount)
    if (!s_isLoading){
      if (s_registryAccount && s_eraAccount) {
        set_s_noAccountModal(false)
      } else {
        set_s_noAccountModal(true)
      }
    }
  }, [s_registryAccount, s_eraAccount, s_isLoading])

  useEffect(() => {
    if (isAddressComplete) {
      const delay = setTimeout(() => {
        set_s_liveAddress(constructAddress(watchedAddress));
        set_s_showPreview(true);
      }, 500); // Adjust delay time (500ms) to avoid flickering
  
      return () => clearTimeout(delay); // Cleanup on rapid input changes
    } else {
      set_s_showPreview(false);
    }
  }, [watchedAddress, isAddressComplete]);

  
  if (s_isLoading) return (
    <Spinner />
  )

  return (
    <div className='flex justify-center'>
      <Card className='mt-12 w-[650px] gap-4 p-6 '>
        <CardHeader className='text-left'>
          <CardTitle className='font-bold  text-[1.8rem]/[2rem] text-black mb-1'>{t("copilot.inputFormTitle")}</CardTitle>
          <CardDescription className='text-gray-500 font-medium'>
            {t("copilot.inputFormDesc")}
          </CardDescription>
        </CardHeader>

        <div className='w-full px-6'>
          <Separator className='bg-gray-300 h-[1px] w-full my-8' />
        </div>

        <CardContent className='text-left pt-4'>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-8'>
              {/* Linked Account */}
              <FormField
                name=''
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-bold text-2xl/[2rem]">{t("copilot.linkedAccountTitle")}</FormLabel>
                    <div className='flex w-full justify-between gap-4'>
                      <div className='flex flex-col w-full gap-2 mt-6'>
                        <p className='text-md font-semibold text-gray-500'>{t("copilot.registryUsernameLabel")}</p>
                        <Input className='w-full text-[15px]/[1.5rem]' clearable={false} type="text" disabled value={s_registryAccount ? s_registryAccount.username : null} />
                      </div>
                      <div className='flex flex-col w-full gap-2 mt-6'>
                        <p className='text-md font-semibold text-gray-500'>{t("copilot.eraUsernamePlaceholder")}</p>
                        <Input className='w-full text-[15px]/[1.5rem]' clearable={false} type="text" disabled value={s_eraAccount ? s_eraAccount.username : null} />
                      </div>
                    </div>
                  </FormItem>
                )}
              />

              {/* Case Name Input */}
              <FormField
                name='caseName'
                control={form.control}
                rules={{ required: t("copilot.inputError") || "Case Name is required"}}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel className="font-bold text-2xl/[2rem]">{t("copilot.caseNameLabel")}</FormLabel>
                    <FormControl className='mt-6'>
                      <Input className='text-[15px]/[1.5rem]' placeholder={t("copilot.caseNamePlaceholder")} {...field} />
                    </FormControl>
                    <div className="text-red-500 text-xs min-h-[1rem]">
                      <div className="min-h-[1rem] w-full items-center" >
                        {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                      </div>
                    </div>
                  </FormItem>
                )}
              />

              
              {/* Address Input */}
              <div className='flex flex-col gap-4'>
                <h3 className='text-2xl font-bold'>{t("copilot.addressInputLabel")}</h3>
                <div className="flex flex-wrap items-center gap-2">
                  {/* City */}
                  <FormField 
                    name="city"
                    control={form.control}
                    render={({field}) => (
                      <FormItem className='flex-col items-start'>
                        <div className="flex items-center">
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.cityPlaceholder")} disabled className="w-24 text-[15px]/[1.5rem] text-left" />
                          </FormControl>
                          <span className='ml-1'>{t("copilot.cityLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" />
                      </FormItem>
                    )}
                  />

                  {/* District */}
                  <FormField 
                    name="district"
                    control={form.control}
                    rules={{
                      required: t("copilot.inputError"),
                      pattern: {
                        value: /^[\u4e00-\u9fa5]+$/u,
                        message: t("copilot.chineseCharacterValidation"),
                      },
                    }}
                    render={({field, fieldState}) => (
                      <FormItem className='flex flex-col items-start'>
                        <div className="flex items-center">
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.districtPlaceholder")} className='w-20 text-[15px]/[1.5rem] text-left' />
                          </FormControl>
                          <span className='ml-1'>{t("copilot.districtLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Street/Road */}
                  <FormField
                    name="streetNumber"
                    control={form.control}
                    rules={{
                      required: t("copilot.inputError"),
                      pattern: {
                        value: /^[\u4e00-\u9fa5]+$/u,
                        message: t("copilot.chineseCharacterValidation"),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormItem className="flex flex-col items-start">
                        <div className="flex items-center">
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.streetNumberPlaceholder")} className="w-20 text-[15px]/[1.5rem] text-left" />
                          </FormControl>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="streetType"
                    control={form.control}
                    render={({ field, fieldState  }) => (
                      <FormItem className='flex flex-col items-start'>
                        <FormControl>
                          <Select 
                            {...field}
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                          >
                            <SelectTrigger className="w-18 text-[15px]/[1.5rem] text-left">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent >
                              <SelectItem value="街">{t("copilot.streetLabel")}</SelectItem>
                              <SelectItem value="路">{t("copilot.roadLabel")}</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <div className="min-h-[1rem] w-full items-center" />
                      </FormItem>
                    )}
                  />
                  
                  {/* Section */}
                  <FormField
                    name="section"
                    control={form.control}
                    rules={{
                      pattern: {
                        value: /^[\u4e00-\u9fa5]+$/u,
                        message: t("copilot.chineseCharacterValidation"),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormItem className="flex flex-col items-center">
                        <div className='flex items-center'>
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.sectionPlaceholder")} className="w-20 text-[15px]/[1.5rem] text-left" />
                          </FormControl>
                          <span className="ml-1">{t("copilot.sectionLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full align-middle" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Lane */}
                  <FormField 
                    name="lane"
                    control={form.control}
                    rules={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: t("copilot.numberValidationError"),
                      },
                    }}
                    render={({field, fieldState}) => (
                      <FormItem className='flex flex-col items-start'>
                        <div className='flex items-center'>
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.lanePlaceholder")} className='w-20 text-[15px]/[1.5rem] text-left' />
                          </FormControl>
                          <span className='ml-1'>{t("copilot.laneLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Alley */}
                  <FormField 
                    name="alley"
                    control={form.control}
                    rules={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: t("copilot.numberValidationError"),
                      },
                    }}
                    render={({field, fieldState}) => (
                      <FormItem className='flex flex-col items-start'>
                        <div className="flex items-center">
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.alleyPlaceholder")} className='w-20 text-[15px]/[1.5rem] text-left' />
                          </FormControl>
                          <span className='ml-1'>{t("copilot.alleyLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />


                  {/* Number */}
                  <FormField
                    name="number"
                    control={form.control}
                    rules={{
                      required: t("copilot.inputError"),
                      pattern: { value: /^[0-9之]*$/, message: t("copilot.numberValidationError") },
                    }}
                    render={({field, fieldState}) => (
                      <FormItem className="flex flex-col items-start">
                        <div className='flex items-center'>
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.numberPlaceholder")} className="w-20 text-[15px]/[1.5rem] text-left" />
                          </FormControl>
                          <span className="ml-1">{t("copilot.numberLabel")}</span>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Floor */}
                  <FormField
                    name="floor"
                    control={form.control}
                    rules={{
                      pattern: { 
                        // If floor type is 樓 value must be numbers, if 層 value must be chinese characters
                        value: floorType === "樓" ? /^[0-9之]*$/ : /^[\u4E00-\u9FA5]+$/, 
                        message: floorType === "樓" 
                        ? t("copilot.numberValidationError") 
                        : t("copilot.chineseCharacterValidation")
                      },
                    }}
                    render={({field, fieldState}) => (
                      <FormItem className="flex flex-col items-start">
                        <div className='flex items-center'>
                          <FormControl>
                            <Input {...field} clearable={false} placeholder={t("copilot.floorPlaceholder")} className="w-20 text-[15px]/[1.5rem] text-left" />
                          </FormControl>
                        </div>
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                        </div>
                      </FormItem>
                    )}
                  />

                  {/* Floor Type */}
                  <FormField
                    name="floorType"
                    control={form.control}
                    render={({ field }) => (
                      <FormItem className='flex flex-col items-start'>
                        <FormControl>
                          <Select
                            {...field}
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                          >
                            <SelectTrigger className="w-18 text-[15px]/[1.5rem] text-left">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="樓">{t("copilot.floorTypeBuilding")}</SelectItem>
                              <SelectItem value="層">{t("copilot.floorTypeLevel")}</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <div className="min-h-[1rem] w-full items-center" />
                      </FormItem>
                    )}
                  />

                  {/* Floor section */}
                  {floorType === "樓" && (
                    <FormField
                      name="floorSection"
                      control={form.control}
                      rules={{
                        pattern: { value: /^[0-9之]*$/, message: t("copilot.numberValidationError") },
                      }}
                      render={({field, fieldState}) => (
                        <FormItem className="flex flex-col items-start">
                          <div className='flex items-center'>
                            <span className="mr-1">{t("copilot.floorSectionLabel")}</span>
                            <FormControl>
                              <Input {...field} clearable={false} placeholder={t("copilot.floorSectionPlaceholder")} className="w-20 text-[15px]/[1.5rem] text-left" />
                            </FormControl>
                          </div>
                          <div className="min-h-[1rem] w-full items-center" >
                            {fieldState.error && <FormMessage className="pl-1 text-red-500 text-xs">{fieldState.error.message}</FormMessage>}
                          </div>
                        </FormItem>
                      )}
                    />
                  )}
                </div>
              </div>
              
              {s_showPreview && (
                <div className='flex flex-col gap-2 p-4 w-full font-medium bg-[#E3EFFC] border-l-4 border-[#0093D7] min-h-[100px]'>
                  <p className='text-sm font-medium text-[#0093D7]'>{t("copilot.recheckAddress")}</p>
                  <p className="text-3xl font-medium text-[#0093D7]">{s_liveAddress}</p>
                </div>
              )}

              {/* Owner ID Input */}
              {form.watch("registryInfo") === "no" && (
                <>
                  <FormItem>
                    <div className='flex flex-col gap-2'>
                      <div className='flex gap-2 items-center'>
                        <FormLabel className="font-bold text-2xl/[2rem]">{t("copilot.ownerIDLabel")}</FormLabel>
                        <CustomButton
                          type="button"
                          variant="special"
                          onClick={() => appendOwner({ id: ""})}
                          className='w-10 h-10 text-2xl font-medium'
                        >
                          +
                        </CustomButton>
                      </div>
                      <FormDescription className='font-medium text-md text-gray-500'>{t("copilot.ownerIDDesc")}</FormDescription>
                    </div>

                    
                    <div className='w-full'>
                      <Separator className={`bg-gray-300 h-[1px] mt-6 w-full ${ownerFields.length > 0 ? "mb-8": ""}`} />
                    </div>


                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center mt-0'>
                      {ownerFields.map((item, index) => (
                        <FormField
                          key={item.id}
                          control={form.control}
                          name={`ownerID.${index}.id`}
                          rules={{
                            validate: (value) => 
                              !value || 
                              validateTaiwanID(value) || 
                              /^[0-9]{4,5}$/.test(value) ? true :
                              t("copilot.invalidOwnerID")
                          }}
                          render={({ field, fieldState }) => (
                            <div key={item.id} className='flex gap-2 items-top'>
                              <FormControl>
                                <div className='flex flex-col gap-1'>
                                  <Input
                                    {...field}
                                    placeholder={t("copilot.ownerIDPlaceholder")}
                                    className="w-full text-[15px]/[1.5rem]"
                                  />
                                  <div className="text-red-500 pl-2 text-xs min-h-[1rem]">
                                    <div className="min-h-[1rem] w-full items-center" >
                                      {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                                    </div>
                                  </div>
                                </div>
                              </FormControl>
                              {ownerFields.length > 0 && (
                                <CustomButton 
                                  type="button"
                                  variant="danger" 
                                  onClick={() => removeOwner(index)}
                                >
                                  -
                                </CustomButton>
                              )}
                            </div>
                          )}
                        >
                          
                        </FormField>
                      ))}
                    </div>
                  </FormItem>
                  
                  {form.watch("ownerID").length > 0 && (
                    <FormItem>
                      <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 items-center'>
                          <FormLabel className="font-bold text-2xl/[2rem]">{t("copilot.percentageLabel")}</FormLabel>
                          <CustomButton
                            type="button"
                            variant="special"
                            disabled={!canAddPercentage}
                            onClick={() => {
                              const ownerCount = form.getValues("ownerID")?.length || 0;
                              const percentageCount = form.getValues("percentage")?.length || 0;
                              // console.log("ownercount", ownerCount)
                              // console.log("percentageCount", percentageCount)
                              if (percentageCount < ownerCount) {
                                appendPercentage({ id: "" });
                              } else {
                                alert(t("copilot.addPercentageAlert"));
                              }
                            }}
                            className='w-10 h-10 text-2xl font-medium'
                          >
                            +
                          </CustomButton>
                        </div>
                        <FormDescription className='font-medium text-md text-gray-500'>{t("copilot.percentageDesc")}</FormDescription>
                      </div>

                      
                      <div className='w-full'>
                        <Separator className={`bg-gray-300 h-[1px] mt-6 w-full ${percentageFields.length > 0 ? "mb-8": ""}`} />
                      </div>


                      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center mt-0'>
                        {percentageFields.map((item, index) => {
                          const currentOwnerID = watchedOwnerIDs?.[index]?.id?.trim();
                          
                          if (!currentOwnerID || currentOwnerID.trim() === "") {
                            return null;
                          }

                          return (
                            <FormField
                              key={item.id}
                              control={form.control}
                              name={`percentage.${index}.id`}
                              rules={{
                                validate: (value) => 
                                  !value || /^[0-9]{1,3}$/.test(value)
                                    ? true
                                    : t("copilot.invalidPercentage")
                              }}
                              render={({field, fieldState}) => (
                                <div className='flex gap-2 items-top'>
                                  <FormControl>
                                    <div className='flex flex-col gap-1'>
                                      <p className='font-bold'>{currentOwnerID} :</p>
                                      <div className='flex'>
                                        <Input {...field} placeholder={t("copilot.percentagePlaceholder")} className='w-full text-[15xp]/[1.5rem]' />
                                        <div className='text-red-500 pl-2 text-xs min-h-[1rem]'>
                                          <div className='min-h-[1rem] w-full items-center'>
                                            {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                                          </div>
                                        </div>
                                        <CustomButton
                                          type="button"
                                          variant="danger"
                                          onClick={() => removePercentage(index)}
                                        >
                                          -
                                        </CustomButton>
                                      </div>
                                    </div>
                                  </FormControl>
                                </div>
                              )}
                            />
                          )
                        })}
                      </div>
                    </FormItem>
                  )}
                  
                </>
              )}

              {/* <FormField
                name="registryUsername"
                control={form.control}
                rules={{ required: t("copilot.registryUsernameError") || 'Registry username is required' }}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel className="font-bold text-xl/[2rem]">{t("copilot.registryUsernameLabel")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("copilot.registryUsernamePlaceholder")} {...field} />
                    </FormControl>
                    <div className="text-red-500 text-xs min-h-[1rem]">
                    <d className="min-h-[1rem]" iv>
                      </div>
                    {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                  </FormItem>
                )}
              />

              <FormField
                name="registryPassword"
                control={form.control}
                rules={{ required: t("copilot.registryPasswordError") || 'Registry password is required' }}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel className="font-bold text-xl/[2rem]">{t("copilot.registryPasswordLabel")}</FormLabel>
                    <FormControl>
                      <Input type="password" placeholder={t("copilot.registryPasswordPlaceholder")} {...field} />
                    </FormControl>
                    <div className="text-red-500 text-xs min-h-[1rem]">
                    <d className="min-h-[1rem]" iv>
                      </div>
                    {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                  </FormItem>
                )}
              /> */}

              {/* ---------------------------------------------------- */}
              {/* JOSM STILL WORKING ON IT */}
              {/* Registry Info PDF */}
              {/* <FormField
                name="registryInfo"
                control={form.control}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel className='font-bold text-2xl/[2rem]'>{t("copilot.registryInfoQuestion")}</FormLabel>
                    <FormControl>
                      <RadioGroup value={field.value || "no"} onValueChange={field.onChange}>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value="yes" />
                          <FormLabel>{t("copilot.yesLabel")}</FormLabel>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <RadioGroupItem value="no" />
                          <FormLabel>{t("copilot.noLabel")}</FormLabel>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <div className="text-red-500 text-xs min-h-[1rem]">
                      <div className="min-h-[1rem] w-full items-center" >
                        {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                      </div>
                    </div>
                  </FormItem>
                )}
              /> */}

              {/* File Upload */}
              {/* {form.watch("registryInfo") === "yes" && (
              <FormField
                  name="uploadFile"
                  control={form.control}
                  rules={{
                    validate: (value) =>
                      value && value[0]?.type === "application/pdf"
                        ? true
                        : t("copilot.uploadFileError") || "Invalid file format",
                  }}
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel className='font-bold mr-2/[2rem]'>{t("copilot.submitRegistryPdf")}</FormLabel>
                      <FormControl>
                        <FileInput
                          type="file"
                          accept="application/pdf"
                          onChange={(e) => field.onChange(e.target.files)}
                        />
                      </FormControl>
                      <div className="text-red-500 text-xs min-h-[1rem]">
                        <div className="min-h-[1rem] w-full items-center" >
                          {fieldState.error && <FormMessage>{fieldState.error.message}</FormMessage>}
                        </div>
                      </div>
                    </FormItem>
                  )}
                />
              )} */}
              {/* ---------------------------------------------------- */}
            </form>
          </Form>
        </CardContent>

        <CardFooter className='flex justify-center space-x-2'>
          <CustomButton
            variant='secondary'
            type="submit"
            className='w-[200px]'
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isValid || !s_registryAccount || !s_eraAccount}
          >
            {form.formState.isSubmitting ? <Loader size="sm" className="text-white" /> : t("copilot.submitButton")}
          </CustomButton>
        </CardFooter>
      </Card>

      {!s_isLoading && (!s_registryAccount || !s_eraAccount) && (
        <NoAccountModal 
          open={s_noAccountModal}
          message={getNoAccountMessage()}
          onConfirm={() => goToAccountPage()}
        />
      )}


      <ConfirmationModal 
        open={s_isModalOpen}
        message={t("home.backgroundProcess")}
        onConfirm={() => set_s_isModalOpen(false)}
        onCancel={() => set_s_isModalOpen(false)}
      />
    </div>
  );
}

export default CopilotInputFrame;
