import { instance } from "../handlerAPI"

export const createNewCase = (data) => {
    return instance.post("/api/scripts/case-id", data)
}

export const runScrapingScript = (data) => {
    return instance.post("/api/scripts/run", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getPDF = ({ caseName, caseID, userID }) => {
    return instance.get("api/scripts/export-pdf", {
        params: {case_name: caseName, case_id: caseID, user_id: userID},
        responseType: "blob",
    })
}

export const getBackgroundProcessStatus = () => {
    return instance.get("api/scripts/check-process")
}

