import { Edit, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { createUser, deleteUser, getUsers, updateUser } from "../../apis/users/usersAPi";
import UserModal from "../../components/admin/UserModal";
import ConfirmationModal from "../../components/confirmationModal";
import CustomButton from "../../components/reusable/ButtonComponent";
import { DataTable } from "../../components/reusable/TableComponent";
import useAPI from "../../hooks/useAPI";

const UserManagement = () => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [s_users, set_s_users] = useState([])
    const [s_userID, set_s_userID] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [s_userData, set_s_userData] = useState({});
    const [s_isModalOpen, set_s_isModalOpen] = useState(false);
    const [s_isConfirmationModalOpen, set_s_isConfirmationModalOpen] = useState(false);
    const [s_modalMode, set_s_modalMode] = useState("")
    const [s_error, set_s_error] = useState(false);

    const call_getAllUser = useAPI(getUsers)
    const call_createUser = useAPI(createUser)
    const call_updateUser = useAPI(updateUser)
    const call_deleteUser = useAPI(deleteUser)

    const columns = [
        {
            accessorKey: "id",
            header: t("userManagement.id"),
            cell: ({ row }) => (
                <div className="font-medium">
                {row.getValue("id")}
                </div>
            ),
            size: "10%"
        },
        {
            accessorKey: "username",
            header: t("userManagement.username"),
            cell: ({ row }) => (
                <div className="font-medium">
                {row.getValue("username")}
                </div>
            ),
            size: "30%"
        },
        {
            accessorKey: "role",
            header: t("userManagement.role"),
            cell: ({ row }) => (
                <span>{t(`userManagement.${row.getValue("role")}`)}</span>
            ),
            size: "20%"
        },
        {
            accessorKey: "is_active",
            header: t("userManagement.active"),
            cell: ({ row }) => (
                <span>{t(`userManagement.${row.getValue("is_active")}`)}</span>
            ),
            size: "20%"
        },
        {
            accessorKey: "actions",
            header: t("userManagement.actions"),
            cell: ({ row }) => (
                <div className="flex flex-row gap-2">
                    <CustomButton
                    className="text-primary underline"
                    onClick={() => handleButtonUpdate(row.original)}
                    >
                        <Edit className="w-5 h-5" />
                    </CustomButton>
                    <CustomButton
                    className="text-primary underline"
                    onClick={() => set_s_userID(row.original.id)}
                    >
                        <Trash2 className="w-5 h-5" />
                    </CustomButton>
                </div>
            ),
            size: "20%"
        }
    ];

    const handlePageChange = (pageNumber) => {
        if (currentPage !== pageNumber) {
            setCurrentPage(pageNumber)
            call_getAllUser.request({ page: pageNumber})
        }
    };

    const handleCancelModal = () => {
        set_s_isModalOpen(false)
        set_s_modalMode("")
    }

    const handleCreateUser = () => {
        set_s_userData(null);
        set_s_modalMode("create");
    };

    const handleButtonUpdate = (userdata) => {
        console.log("uuserdata:", userdata)
        set_s_userData(userdata);
        set_s_modalMode("edit");
    }

    const handleDeleteUser = () => {
        console.log("s_userID:", s_userID)
        call_deleteUser.request(s_userID)
        set_s_isConfirmationModalOpen(false)
    }

    const handleModalConfirm = (updatedData) => {
        console.log("User Data:", updatedData);
        if (s_modalMode === "edit") {
            const userID = updatedData.id; // Extract userID from updatedData
            if (!updatedData.password) {
                delete updatedData.password; // Avoid sending empty passwords
            }
            console.log("Updated User Data:", updatedData);
            const { username, ...newUpdatedData} = updatedData // Remove username
            call_updateUser.request({userID: userID, userData: newUpdatedData});
        } else if (s_modalMode === "create") {
            call_createUser.request(updatedData);
        }
        set_s_isModalOpen(false);
        set_s_modalMode("")
    };

    useEffect(() => {
        call_getAllUser.request({page: 1})
    }, [])

    useEffect(() => {
        if (s_modalMode === "create" || s_modalMode === "edit"){
            set_s_isModalOpen(true)
        }
    }, [s_modalMode])

    useEffect(() => {
        if (call_getAllUser.status === "suc"){
            set_s_users(call_getAllUser.data.users)
            setTotalPages(call_getAllUser.data.totalPages)
        } else if (call_getAllUser.status === "err"){
            set_s_error(call_getAllUser.msg)
        }
    }, [call_getAllUser.status])

    useEffect(() => {
        if (call_createUser.status === "suc" || call_updateUser.status === "suc") {
            call_getAllUser.request(); // Refresh user list
        }
    }, [call_createUser.status, call_updateUser.status]);

    useEffect(() => {
        if (s_userID){
            set_s_isConfirmationModalOpen(true)
        }
    }, [s_userID])

    useEffect(() => {
        if (call_deleteUser.status === "suc") {
            call_getAllUser.request();
            set_s_userID(null)
        } else if (call_deleteUser.status === "err"){
            // ERROR HANDLING
            set_s_userID(null)
        }
    }, [call_deleteUser.status])


    return (
        <div className="py-12 px-24">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">{t("userManagement.title")}</h1>
                <CustomButton variant="secondary" className="text-white" onClick={handleCreateUser}>
                    + {t("userManagement.addNewUserButton")}
                </CustomButton>
            </div>
            <DataTable
                columns={columns}
                data={s_users}
                onRowClick={() => console.log("rowclicked")}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />

            <UserModal
                mode={s_modalMode}
                open={s_isModalOpen}
                userData={s_userData}
                onConfirm={handleModalConfirm}
                onCancel={handleCancelModal}
            />

            <ConfirmationModal 
                open={s_isConfirmationModalOpen}
                message={t("userManagement.deleteUserConfirm")}
                onConfirm={handleDeleteUser}
                onCancel={() => set_s_isConfirmationModalOpen(false)}
            
            />
        </div>
    )
}

export default UserManagement
