import { ChevronDown, ChevronUp, CircleAlert, CircleCheckBig } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { } from '../context/language';
import CustomButton from './reusable/ButtonComponent';
import { Loader } from './reusable/LoaderComponent';
import StatusError from './status_row_components/statusError';
import StatusRunning from './status_row_components/statusRunning';
import StatusSuccess from './status_row_components/statusSuccess';
import StatusWaiting from './status_row_components/statusWaiting';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card/card";

const StatusTable = (props) => {
  const [isOpened, setIsOpened] = useState(true);
  const { t } = useTranslation(); 

  const isNoOtherRights = props.siteStatus === "noOtherRights"

  const isOtherIllegal = props.siteStatus === "otherInformation"

  // console.log("Props.siteName:", props.siteName, "Props.sitestatus:", props.siteStatus)

  // Log props for debugging
  // console.log("Rendering StatusTable for:", props.siteName);
  // console.log("Current siteStatus:", props.siteStatus);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  const statusComponents = {
    Waiting: <StatusWaiting fields={props.siteFields} description={""} />,
    "Start Scraping": (
      <StatusRunning fields={props.siteFields} description={t("status.startScraping")} />
    ),
    "Saving Data": (
      <StatusRunning fields={props.siteFields} description={t("status.savingData")} />
    ),
    Success: (
      <StatusSuccess fields={props.siteFields} description={t("status.successDescription")} />
    ),
    otherInformation: (
      <StatusSuccess fields={props.siteFields} description={t("status.otherInformation")} />
    ),
    wrongInput: (
      <StatusError fields={props.siteFields} description={t("status.wrongInput")} />
    ),
    Skipped: (
      <StatusError fields={props.siteFields} description={t("status.skipped")} />
    ),
    Error: (
      <StatusError fields={props.siteFields} description={t("status.wrongInput")} />
    ),
  };
  

  const renderStatusRow = () => {
    switch (props.siteStatus) {
      case "Waiting":
        // console.log("Rendering StatusWaiting for", props.siteName);
        return <StatusWaiting fields={props.siteFields} description={""} />;
      case "Start Scraping":
        // console.log("Rendering StatusRunning for", props.siteName);
        return <StatusRunning fields={props.siteFields} description={t("status.startScraping")} />;
      case "Saving Data":
        // console.log("Rendering StatusRunning for", props.siteName);
        return <StatusRunning fields={props.siteFields} description={t("status.savingData")} />;
      case "Success":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusSuccess fields={props.siteFields} description={t("status.successDescription")} />;
      case "otherInformation":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusSuccess fields={props.siteFields} description={t("status.otherInformation")} />;
      case "inputReceived":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusRunning fields={props.siteFields} description={t("status.inputReceived")} />;
      case "noOtherRights":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusError fields={props.siteFields} description={t("status.noOtherRights")} />;
      case "wrongInput":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusError fields={props.siteFields} description={t("status.wrongInput")} />;
      case "Skipped":
        // console.log("Rendering StatusSuccess for", props.siteName);
        return <StatusError fields={props.siteFields} description={t("status.skipped")} />;
      case "Error":
        // console.log("Rendering StatusError for", props.siteName);
        return <StatusError fields={props.siteFields} description={t("status.wrongInput")} />;
      default:
        // console.log("Rendering StatusWaiting (default) for", props.siteName);
        return <StatusError fields={props.siteFields} description={t(`status.${props.siteStatus}`) ?? props.siteStatus} />;
    }
  };

  const renderStatusIcon = () => {
    switch (props.siteStatus) {
      case "Waiting":
        // console.log("Rendering StatusWaiting for", props.siteName);
        return (
          <>
            <Loader className="w-4 h-4 animate-spin" />
          </>
        );
      case "Start Scraping":
        return (
          <>
            <Loader className="w-4 h-4 animate-spin" />
          </>
        )
      case "Success":
        // console.log("Rendering StatusSuccess for", props.siteName);
          return (
          <>
            <CircleCheckBig className="w-4 h-4" />
          </>
        )
      case "otherInformation":
        // console.log("Rendering StatusSuccess for", props.siteName);
          return (
          <>
            <CircleCheckBig className="w-4 h-4" />
          </>
        )
      case "Skipped":
          return (
          <>
            <CircleAlert color='red' className="w-4 h-4" />
          </>
        )
      case "Error":
        // console.log("Rendering StatusError for", props.siteName);
          return (
          <>
            <CircleAlert color='red' className="w-4 h-4" />
          </>
        )
      case "wrongInput":
        // console.log("Rendering StatusError for", props.siteName);
          return (
          <>
            <CircleAlert color='red' className="w-4 h-4" />
          </>
        )
      case "errorFinal":
        // console.log("Rendering StatusError for", props.siteName);
          return (
          <>
            <CircleAlert color='red' className="w-4 h-4" />
          </>
        )
      default:
        // console.log("Rendering StatusWaiting (default) for", props.siteName);
          return (
          <>
            <Loader className="w-4 h-4 animate-spin" />
          </>
        )
    }
  }

  return (
    <Card className="w-full mx-auto mb-4 border shadow-md">
      <CardHeader className={`flex items-center justify-between flex-row h-[40px] ${isOpened ? 'bg-slate-100': 'bg-white'} `}>
        <div className='flex items-center gap-2'>
          <CardTitle className={`text-lg font-semibold text-gray-800 w-[280px] ${isNoOtherRights || isOtherIllegal ? "flex-1" : ""}`}>
            <div className='flex flex-row gap-2'>
              {props.siteName}
              <span className="flex items-center text-green-500">
                {renderStatusIcon()}
              </span>
            </div>
          </CardTitle>
        </div>
        
        {/* Buttons */}
        {/* {(isNoOtherRights || isOtherIllegal) && ( */}
          <div className="flex gap-2 justify-center">
            {isNoOtherRights && (
              <CustomButton
                className='mb-2 w-[200px]' 
                onClick={() => props.setIsIdModalOpen(true)} 
                variant="link"
              >
                {t("output.userInput")}
              </CustomButton>
            )}

            {isOtherIllegal &&(
              <CustomButton
                className='mb-2 w-[200px]'
                onClick={() =>
                  window.open(
                    "https://tccmoapply.dba.tcg.gov.tw/tccmoapply/maliapp/asp/aspsqu01.do?node=20181011170755721001"
                  )
                }
                variant="link"
              >
                {t("output.goToLink")}
              </CustomButton>
            )}
          </div>
        {/* )} */}
        

        {/* Icon */}
        <div className="flex justify-end">
          {isOpened ? (
            <ChevronUp
              className="cursor-pointer text-gray-600"
              onClick={toggle}
            />
          ) : (
            <ChevronDown
              className="cursor-pointer text-gray-600"
              onClick={toggle}
            />
          )}
        </div>
      </CardHeader>

      {isOpened && (
        <CardContent className="border-t p-4">
          {renderStatusRow()}
        </CardContent>
      )}
    </Card>
  );
};

export default StatusTable;
