import { ChevronLeftIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createAccount, deleteAccount, getAccount, updateAccount } from "../apis/account/accountApi";
import { getCompanyDetail } from "../apis/company/companyApi";
import { Button } from "../components/ui/button/button";
import { Dialog, DialogContent, DialogFooter } from "../components/ui/dialog/dialog";
import { Input } from "../components/ui/input/input";
import { AuthContext } from "../context/AuthProvider";
import { } from "../context/language";
import useAPI from "../hooks/useAPI";
import CustomButton from "./reusable/ButtonComponent";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form/form";
import { Separator } from "./ui/separator/separator";
import Spinner from "./ui/spinner/spinner";

const LinkedAccounts = () => {
  const { auth } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [websiteOptions, setWebsiteOptions] = useState();
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [existingAccount, setExistingAccount] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [s_loading, set_s_loading] = useState(false);
  const [s_cardLoading, set_s_cardLoading] = useState(false);
  const [message, setMessage] = useState(t("accountSettings.selectWebsiteMessage"));

  const form = useForm({
    defaultValues: {
      username: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isValid },
  } = form;

  const password = watch("password");

  const call_getAccount = useAPI(getAccount)
  const call_createAccount = useAPI(createAccount)
  const call_updateAccount = useAPI(updateAccount)
  const call_deleteAccount = useAPI(deleteAccount)
  const call_getCompanyDetail = useAPI(getCompanyDetail)

  const handleWebsiteClick = (website) => {
    // console.log("website", website)
    reset()
    set_s_cardLoading(true)
    setExistingAccount(null);
    setSelectedWebsite(website)
    setMessage("Loading")

    call_getAccount.request(website.english_name)
  }

  const handleSaveAccount = (data) => {
    const { username, password } = data
    if (!selectedWebsite) return
    
    const requestBody = {
      account_name: `${selectedWebsite.english_name}_account`,
      username,
      encrypted_password: password,
      users: [auth.id]
    }

    console.log("requestbody:", requestBody)

    if (existingAccount) {
      call_updateAccount.request({accountID: existingAccount.id, accountData: requestBody});
    } else {
      call_createAccount.request(requestBody)
    }
  }

  const handleDeleteAccount = () => {
    if (!existingAccount) return
    call_deleteAccount.request(existingAccount.id)
  }

  useEffect(() => {
    set_s_loading(true)
    call_getCompanyDetail.request()
  }, [])

  useEffect(() => {
    if (call_getCompanyDetail.status === "suc") {
      const accountWebsites = call_getCompanyDetail.data.websites.filter((site) => site.is_account);
      setWebsiteOptions(accountWebsites);
    } else if (call_getCompanyDetail.status === "err") {
      console.error("Error fetching company data:", call_getCompanyDetail.msg);
      setMessage(call_getCompanyDetail.msg)
    }
  }, [call_getCompanyDetail.status])

  useEffect(() => {
    if (call_getAccount.status === "suc") {
      // console.log("data:", call_getAccount.data)
      // console.log("selectedwebsite:", selectedWebsite)
      const account = call_getAccount.data.find((acc) => 
      acc.account_name.includes(selectedWebsite.english_name))
      if (account) {
        // console.log("accouunt:", account)
        setExistingAccount(account)
        // console.log("Auto-filling username:", account.username);
        setValue("username", account.username)
      }
      set_s_cardLoading(false)
    }
  }, [call_getAccount.status])

  useEffect(() => {
    if (call_createAccount.status === "suc") {
      setMessage(t("accountSettings.accountLinked"))
      reset()
      setExistingAccount(null)
      setSelectedWebsite(null)
    }
  }, [call_createAccount.status])

  useEffect(() => {
    if (call_updateAccount.status === "suc") {
      setMessage(t("accountSettings.accountUpdated"))
      reset()
      setExistingAccount(null)
      setSelectedWebsite(null)
    }
  }, [call_updateAccount.status])

  useEffect(() => {
    if (call_deleteAccount.status === "suc") {
      setMessage(t("accountSettings.accountDeleted"))
      reset()
      setExistingAccount(null)
      setSelectedWebsite(null)
      setDeleteModalOpen(null)
    }
  }, [call_deleteAccount.status])

  useEffect(() => {
    if (websiteOptions) {
      set_s_loading(false)
    }
  }, [websiteOptions])

  // Trigger validation
  useEffect(() => {
    setTimeout(() => {
      form.trigger("confirmPassword")
    }, 2000);
  }, [password])

  
  if (s_loading) return (
    <Spinner />
  )

  return (
    <div className="flex flex-col items-center py-10 px-24">
      <div className="flex w-full items-center mb-6">
        <Button className="mr-4" variant="ghostwithHover" onClick={() => navigate("/account-settings/manage")}>
          <ChevronLeftIcon className="h-6 w-6 justify-center" />
        </Button>
        <h2 className="text-4xl font-bold">{t("accountSettings.header")}</h2>
      </div>

      <div className="flex w-full gap-6">
        {/* Website Options */}
          <div className="flex flex-col gap-4 w-1/4">
            {websiteOptions && websiteOptions.map((site) => (
              <Button
                key={site.english_name}
                variant={selectedWebsite?.english_name === site.english_name ? "selected" : "unselected"}
                onClick={() => handleWebsiteClick(site)}
                className="text-[15px]/[1.5rem]"
              >
                {i18n.language === "en" ? site.english_name : (site.chinese_name === "地政資訊e點通" ? t("accountSettings.registyInfoButton") : t("accountSettings.eraButton"))}
              </Button>
            ))}
          </div>

        {/* Account Form */}
        {!s_cardLoading ? (
          <div className="flex-1 bg-white p-6 border rounded-lg">
            {selectedWebsite ? (
              <>
                <div className="flex flex-col w-full">
                  <div className="flex w-full gap-4">
                    <h2 className="text-2xl font-extrabold mb-4">
                      {selectedWebsite.chinese_name === "地政資訊e點通" ? t("accountSettings.registyInfoButton") : t("accountSettings.eraButton")}
                    </h2>
                    {existingAccount ? (
                      <p className="text-green-500 font-extrabold text-2xl mb-4">
                        {t("accountSettings.accountExist")}
                      </p>
                    ) : (
                      <p className="text-red-600 font-extrabold text-2xl mb-4">
                        {t("accountSettings.accountDoesntExist")}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    {selectedWebsite.chinese_name === "地政資訊e點通" 
                    ? (
                    <>
                      <p className="text-[15px]/[1.5rem] font-medium text-gray-500">{t("accountSettings.registryInfoDesc1")}</p>
                      <p className="text-[15px]/[1.5rem] font-medium text-gray-500">{t("accountSettings.registryInfoDesc2")}</p>
                      <p className="text-[15px]/[1.5rem] font-medium text-gray-500">{t("accountSettings.registryInfoDesc3")}</p>
                    </>
                    )
                    : (
                      <>
                        <p className="text-[15px]/[1.5rem] font-medium text-gray-500">{t("accountSettings.eraDesc1")}</p>
                        <p className="text-[15px]/[1.5rem] font-medium text-gray-500">{t("accountSettings.eraDesc2")}</p>
                      </>
                    )}
                    
                  </div>
                </div>

                <div className='flex justify-center mt-4 my-4'>
                  <Separator />
                </div>

                <Form {...form}>
                  <form onSubmit={handleSubmit(handleSaveAccount)} className="space-y-4">
                    {/* Username Field */}
                    <FormField
                      name="username"
                      control={form.control}
                      rules={{ required: t("copilot.inputError") }}
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex gap-1">
                            <FormLabel>{t("accountSettings.usernameLabel")}</FormLabel>
                            
                          </div>
                          <FormControl>
                            <Input
                              {...field}
                              className="text-[15px]"
                              value={form.watch("username")} // Controlled value
                              onChange={(e) => setValue("username", e.target.value)} // Update value programmatically
                            />
                          </FormControl>
                          <FormMessage>{errors.username?.message}</FormMessage>
                        </FormItem>
                      )}
                    />
            
                    {/* Password Field */}
                    <FormField
                      name="password"
                      control={form.control}
                      rules={{ required: t("copilot.inputError") }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("accountSettings.passwordLabel")}</FormLabel>
                          <FormControl>
                            <Input className="text-[15px]" type="password" {...field} />
                          </FormControl>
                          <FormMessage>{errors.password?.message}</FormMessage>
                        </FormItem>
                      )}
                    />
            
                    {/* Confirm Password Field */}
                    <FormField
                      name="confirmPassword"
                      control={form.control}
                      rules={{
                        validate: (value) =>
                          value === password || t("accountSettings.passwordMismatchError"),
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t("accountSettings.confirmPassword")}</FormLabel>
                          <FormControl>
                            <Input className="text-[15px]" type="password" {...field} />
                          </FormControl>
                          <FormMessage>{errors.confirmPassword?.message}</FormMessage>
                        </FormItem>
                      )}
                    />
            
                    <CustomButton
                      type="submit" 
                      variant="secondary"
                      disabled={!form.formState.isValid}
                    >
                      {t("accountSettings.saveAccount")}
                    </CustomButton>
                  </form>
                </Form>
              </>
            ) : (
              <p className="text-[15px]/[1.5rem]">{message}</p>
            )}
          </div>
        ) : (
          <div className="flex bg-white p-6 border rounded-lg w-full h-[350px] justify-center items-center">
              <Spinner size="h-[50px]" />
          </div>
        )
      }
      </div>


      {/* Confirmation Modal */}
      <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <DialogContent>
          <p>{t("accountSettings.deleteConfirmationMessage")}</p>
          <DialogFooter>
            <Button variant="default" onClick={() => setDeleteModalOpen(false)}>
              {t("accountSettings.cancelButton")}
            </Button>
            <Button variant="destructive" onClick={handleDeleteAccount}>
              {t("accountSettings.confirmDeleteButton")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LinkedAccounts;
