import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "../reusable/ButtonComponent";
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "../ui/dialog/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form/form";
import { Input } from "../ui/input/input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select/select";

const PaymentModal = ({ open, paymentData, mode = "edit", onConfirm, onCancel, companyId, errorMessage }) => {
    const { t } = useTranslation();

    // console.log("paymentdata", paymentData)

    const form = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            id: paymentData?.id || null,
            name: paymentData?.name || "",
            price: paymentData?.price || 0,
            credit_amount: paymentData?.credit_amount || 0,
            is_highlighted: paymentData?.is_highlighted || false,
        },
    });

    const { handleSubmit, reset, formState: { errors }, setValue } = form;

    const handleFormSubmit = (data) => {
        // console.log("formsubmit data:", data)

        const formattedData = {
            ...data,
            credit_amount: Number(data.credit_amount),
            price: Number(data.price)
        }
        if (mode === "create" && !companyId) {
            alert("Company ID is required for creating a payment plan.");
            return;
        }
        onConfirm({ ...formattedData, company_id: companyId });
    };

    // useEffect(() => {
    //     reset({
    //         id: paymentData?.id || null,
    //         name: paymentData?.name || "",
    //         price: paymentData?.price || 0,
    //         credit_amount: paymentData?.credit_amount || 0,
    //         is_highlighted: paymentData?.is_highlighted || false,
    //     });
    // }, [paymentData, reset]);

    useEffect(() => {
        if (paymentData && Object.keys(paymentData).length > 0) {
            setValue("id", paymentData.id || null);
            setValue("name", paymentData.name || "");
            setValue("price", paymentData.price || 0);
            setValue("credit_amount", paymentData.credit_amount || 0);
            setValue("is_highlighted", paymentData.is_highlighted || false);
        }
    }, [paymentData, setValue]);
    

    return (
        <Dialog open={open} onOpenChange={onCancel} className="pt-6">
            <DialogContent className="text-center space-y-4">
                <DialogTitle>
                    <h3 className="text-xl font-bold">
                        {mode === "edit" ? t("paymentModal.editPayment") : t("paymentModal.createPayment")}
                    </h3>
                </DialogTitle>

                {errorMessage && <p className="text-red-500 text-md">{errorMessage}</p>}

                <Form {...form}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <FormField
                            name="name"
                            control={form.control}
                            rules={{ required: "Name is required" }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("paymentModal.name")}
                                    </FormLabel>
                                    <FormControl>
                                        <Input {...field} placeholder={t("paymentModal.namePlaceholder")} />
                                    </FormControl>
                                    <FormMessage>{errors.name?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="price"
                            control={form.control}
                            rules={{
                                required: "Price is required",
                                validate: (value) =>
                                    value > 0 || "Price must be greater than 0",
                            }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("paymentModal.price")}
                                    </FormLabel>
                                    <FormControl>
                                        <Input 
                                            type="number" 
                                            {...field} 
                                            placeholder={t("paymentModal.pricePlaceholder")} 
                                            onChange={(e) => field.onChange(Number(e.target.value))}
                                        />
                                    </FormControl>
                                    <FormMessage>{errors.price?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="credit_amount"
                            control={form.control}
                            rules={{
                                required: "Credit Amount is required",
                                validate: (value) =>
                                    value > 0 || "Credit Amount must be greater than 0",
                            }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("paymentModal.credit")}
                                    </FormLabel>
                                    <FormControl>
                                        <Input type="number" {...field} placeholder={t("paymentModal.creditPlaceholder")} />
                                    </FormControl>
                                    <FormMessage>{errors.credit_amount?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="is_highlighted"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("paymentModal.highlighted")}
                                    </FormLabel>
                                    <FormControl>
                                        <Select
                                            onValueChange={(value) => field.onChange(value === "true")}
                                            value={field.value ? "true" : "false"}
                                        >
                                            <SelectTrigger className="border rounded-md p-2">
                                                {field.value ? t("paymentModal.yes") : t("paymentModal.no")}
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="true">{t("paymentModal.yes")}</SelectItem>
                                                <SelectItem value="false">{t("paymentModal.no")}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage>{errors.is_highlighted?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <DialogFooter className="flex justify-end mt-4 space-x-4">
                            <CustomButton variant="default" onClick={onCancel}>
                                Cancel
                            </CustomButton>
                            <CustomButton
                                variant="primary"
                                onClick={handleSubmit(handleFormSubmit)}
                                disabled={Object.keys(errors).length > 0}
                            >
                                {mode === "edit" ? t("paymentModal.save") : t("paymentModal.create")}
                            </CustomButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default PaymentModal;