import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAPI from '../../hooks/useAPI'
import { getCompanies } from '../../apis/company/companyApi'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select/select'
import { getUserActivitiesReport, getUserCredits, getUsers } from '../../apis/users/usersAPi'
import { DataTable } from '../../components/reusable/TableComponent'

const UserActivitiesManagement = () => {
  const { t } = useTranslation()
  const [s_companies, set_s_companies] = useState([])
  const [s_selectedCompany, set_s_selectedCompany] = useState(null)
  const [s_users, set_s_users] = useState([])
  const [s_selectedUsers, set_s_selectedUsers] = useState(null)
  const [s_userCredits, set_s_userCredits] = useState(null)
  const [s_tableData, set_s_tableData] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const call_getCompanies = useAPI(getCompanies)
  const call_getUsers = useAPI(getUsers)
  const call_getUserActivitiesReport = useAPI(getUserActivitiesReport)
  const call_getUserCredits = useAPI(getUserCredits)

  const columns = [
    {
      accessorKey: "company_name",
      header: t("userActivities.companyName"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("company_name")}
        </div>
      )
    },
    {
      accessorKey: "username",
      header: t("userActivities.username"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("username")}
        </div>
      )
    },
    {
      accessorKey: "case_id",
      header: t("userActivities.caseID"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("case_id")}
        </div>
      )
    },
    {
      accessorKey: "case_name",
      header: t("userActivities.caseName"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("case_name")}
        </div>
      )
    },
    {
      accessorKey: "address",
      header: t("userActivities.address"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("address")}
        </div>
      ),
      size: "50%"
    },
    {
      accessorKey: "used_date",
      header: t("userActivities.usedDate"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("used_date")}
        </div>
      ),
      size: "20%"
    },
    {
      accessorKey: "coupon_id",
      header: t("userActivities.couponID"),
      cell: ({row}) => (
        <div className='font-medium'>
          {row.getValue("coupon_id")}
        </div>
      ),
    },
    {
      accessorKey: "deduction",
      header: t("userActivities.deduction"),
      cell: () => (
        <div className='font-medium'>
          -1
        </div>
      )
    },
  ]

  const handleSelectCompany = (companyId) => {
    set_s_selectedCompany(companyId);
    set_s_selectedUsers(null); // Reset user selection
    set_s_tableData([]);
    // Filter users based on the selected company
    call_getUsers.request(companyId)
  };

  const handleSelectUsers = (userID) => {
    set_s_selectedUsers(userID);
    // Filter users based on the selected company
    call_getUserActivitiesReport.request(userID)
    call_getUserCredits.request(userID)
  };


  useEffect(() => {
    call_getCompanies.request()
  }, [])

  useEffect(() => {
    if (call_getCompanies.status === "suc") {
      set_s_companies(call_getCompanies.data)
    } else if (call_getCompanies.status === "err"){
      console.error(call_getCompanies.msg)
    }
  }, [call_getCompanies.status])

  useEffect(() => {
    if (call_getUsers.status === "suc") {
      set_s_users(call_getUsers.data)
    } else if (call_getUsers.status === "err"){
      console.error(call_getUsers.msg)
    }
  }, [call_getUsers.status])

  useEffect(() => {
    if (call_getUserActivitiesReport.status === "suc") {
      set_s_tableData(call_getUserActivitiesReport.data)
    } else if (call_getUserActivitiesReport.status === "err"){
      console.error(call_getUserActivitiesReport.msg)
    }
  }, [call_getUserActivitiesReport.status])

  useEffect(() => {
    if (call_getUserCredits.status === "suc") {
      set_s_userCredits(call_getUserCredits.data.totalAmount)
    } else if (call_getUserCredits.status === "err"){
      console.error(call_getUserCredits.msg)
    }
  }, [call_getUserCredits.status])

  return (
    <div className='py-12 px-24 gap-4'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className="text-3xl font-bold">{t("userActivities.title")}</h1>
      </div>

      <div className='flex flex-col gap-4'>
        
        <div className='flex flex-col gap-1'>
          <p className='text-lg font-bold'>{t("userActivities.company")}</p>
          <Select
            onValueChange={handleSelectCompany}
            value={s_selectedCompany}
          >
            <SelectTrigger>
              <SelectValue placeholder={"Select Company"} />
            </SelectTrigger>
            <SelectContent>
              {s_companies.map((company) => (
                <SelectItem key={company.id} value={company.id}>
                  {company.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>      
        </div>
        
        <div className='flex flex-col gap-1'>
          <p className='text-lg font-bold'>{t("userActivities.user")}</p>
          <Select
            onValueChange={handleSelectUsers}
            value={s_selectedUsers}
            disabled={!s_users.length} 
          >
            <SelectTrigger>
              <SelectValue placeholder={"Select Users"} />
            </SelectTrigger>
            <SelectContent>
              {s_users.map((user) => (
                <SelectItem key={user.id} value={user.id}>
                  {user.username}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
      </div>


      <div className='mt-8 w-full flex justify-end mb-3 pr-4'>
        <p className='text-xl font-semibold'>{t("userActivities.userRemainingCredits", {userCredit: s_userCredits})}</p>
      </div>
      <DataTable 
        columns={columns}
        data={s_tableData}
        onRowClick={() => console.log("rowclicked")}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default UserActivitiesManagement
