import { Loader } from "lucide-react";
import { useTranslation } from "react-i18next";
import StatusRow from "../statusRow";

const StatusRunning = (props) => {
  const { t } = useTranslation();
  return (
    <StatusRow
      fields={props.fields}
      description={props.description}
      status="running"
      icon={<Loader className="w-4 h-4 text-white animate-spin" />}
      badgeText={t("status.running") || "讀取中"}
    />
  );
};

export default StatusRunning;
