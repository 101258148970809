import axios from "axios";
import qs from "qs";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const instance = axios.create({
    timeout: 40000,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        post: {
            "Content-Type": "application/json",
        },
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = cookies.get("authData")?.accessToken;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        if (config.method === "get") {
            config.paramsSerializer = (params) => qs.stringify(params, {arrayFormat: "indices"});
        }
        return config
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    async (response) => {
        // console.log("response in interceptor:", response)
        if (response.config.responseType === "blob"){
            return {
                status: true,
                msg: "Data Blob received",
                data: response.data
            }
        }

        if (response.status === 200 || response.status === 201) {
            return {
                status: true,
                msg: response.data.msg,
                data: response.data.data,
            };
        } else if (response.status === 403) {
            window.location.assign("/login")
        } else {
            return {
                status: false,
                msg: response.data.msg || `${response.config.url} >>> Server connection failed`,
                data: response.data.data,
            };
        }
    },
    (err) => {
        const {response} = err;
        if (response) {
            return {
                status: false,
                data: {},
                msg: errorHandler(response.status, response.data),
            };
        } else {
            return {
                status: false,
                data: {},
                msg: "Request Timeout"
            };
        }
    }
);

const errorHandler = (status, other) => {
    switch (status){
        case 400:
            return "Validation Failed 400";
        case 401:
            return "Authentication Failed 401";
        case 403:
            return "403";
        case 404:
            return "Request Not Found 404";
        default:
            console.log("Unrecognized error:", other)
            return "An unexpected error occurred"
    }
}