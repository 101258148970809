import axios from 'axios';
import Cookies from 'universal-cookie';

// Get the base URL from environment variables
const baseURL = process.env.REACT_APP_BASE_URL;

// Log the base URL for debugging
// console.log(`You are now listening on ${baseURL}`);

// Create a new instance of axios with the base URL
export const axiosServer = axios.create({
  baseURL: baseURL,
});

// Set up an interceptor to add the JWT token to every request
axiosServer.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const token = cookies.get('authData')?.accessToken;  // Get the JWT token from cookies
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;  // Add the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
