import { Check, Edit, Trash2, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCompanies } from "../../apis/company/companyApi";
import { createPaymentPlan, deletePaymentPlan, getPaymentPlansByID, updatePaymentPlan } from "../../apis/payments/paymentApi";
import PaymentModal from "../../components/admin/PaymentModal";
import ConfirmationModal from "../../components/confirmationModal";
import CustomButton from "../../components/reusable/ButtonComponent";
import { DataTable } from "../../components/reusable/TableComponent";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select/select";
import useAPI from "../../hooks/useAPI";

const PaymentPlanManagement = () => {
  const {t} = useTranslation()
  const [s_companies, set_s_companies] = useState([])
  const [s_selectedCompany, set_s_selectedCompany] = useState(1)
  const [s_paymentPlans, set_s_paymentPlans] = useState([])
  const [s_paymentID, set_s_paymentID] = useState(null)
  const [s_error, set_s_error] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [s_modalMode, set_s_modalMode] = useState("")
  const [s_paymentData, set_s_paymentData] = useState({})
  const [s_isModalOpen, set_s_isModalOpen] = useState(false)
  const [s_isConfirmationModalOpen, set_s_isConfirmationModalOpen] = useState(false)

  const call_getPaymentPlans = useAPI(getPaymentPlansByID)
  const call_getCompanies = useAPI(getCompanies)
  const call_createPaymentPlan = useAPI(createPaymentPlan)
  const call_updatePaymentPlan = useAPI(updatePaymentPlan)
  const call_deletePaymentPlan = useAPI(deletePaymentPlan)

  const columns = [
    {
      accessorKey: "name",
      header: t("adminPaymentPlan.name"),
      cell: ({row}) => (
        <div className="font-medium">
          {row.getValue("name")}
        </div>
      )
    },
    {
      accessorKey: "company_name",
      header: t("adminPaymentPlan.company"),
      cell: ({row}) => (
        <span>
          {row.getValue("company_name")}
        </span>
      )
    },
    {
      accessorKey: "price",
      header: t("adminPaymentPlan.price"),
      cell: ({row}) => (
        <span>
          NT ${row.getValue("price")}
        </span>
      )
    },
    {
      accessorKey: "credit_amount",
      header: t("adminPaymentPlan.credit_amount"),
      cell: ({row}) => (
        <span>
          {row.getValue("credit_amount")} {t("adminPaymentPlan.credits")}
        </span>
      )
    },
    {
      accessorKey: "is_highlighted",
      header: t("adminPaymentPlan.highlighted"),
      cell: ({ row }) => (
        <div className="flex justify-center">
          {row.getValue("is_highlighted") ? (
            <Check className="w-5 h-5 text-green-500" />
          ) : (
            <X className="w-5 h-5 text-red-500" />
          )}
        </div>
      ),
      size: "10%",
    },
    {
      accessorKey: "actions",
      header: t("adminPaymentPlan.actions"),
      headerClassName: "text-center",
      cell: ({ row }) => (
          <div className="flex flex-row gap-2">
              <CustomButton
              className="text-primary underline"
              onClick={() => handleButtonUpdate(row.original)}
              >
                  <Edit className="w-5 h-5" />
              </CustomButton>
              <CustomButton
              className="text-primary underline"
              onClick={() => set_s_paymentID(row.original.id)}
              >
                  <Trash2 className="w-5 h-5" />
              </CustomButton>
          </div>
      ),
      size: "20%"
  }
  ]


  const handleSelectCompany = (companyId) => {
    set_s_selectedCompany(companyId);
    // Filter payment plans based on the selected company
    call_getPaymentPlans.request(companyId);
  };

  const handleCancelModal = () => {
    set_s_isModalOpen(false)
    set_s_modalMode("")
  }

  const handleButtonUpdate = (data) => {
    // console.log('data', data)
    set_s_paymentData(data || {});
    set_s_modalMode("edit");
  }

  const handleCreatePaymentPlan = () => {
    set_s_modalMode("create")
  }

  const handleModalConfirm = (updatedData) => {
    // console.log("Paymentdata:", updatedData);
    if (s_modalMode === "edit") {
        const paymentId = updatedData.id;
        call_updatePaymentPlan.request({ paymentID: paymentId, paymentData: updatedData })
    } else if (s_modalMode === "create") {
        call_createPaymentPlan.request(updatedData)
    }
    set_s_isModalOpen(false);
    set_s_modalMode("")
  };

  const handleDeletePlan = () => {
    call_deletePaymentPlan.request(s_paymentID)
    set_s_isConfirmationModalOpen(false)
  }

  useEffect(() => {
    call_getCompanies.request()
  }, [])

  useEffect(() => {
    call_getPaymentPlans.request(s_selectedCompany)
  }, [s_selectedCompany])

  useEffect(() => {
    if (call_getCompanies.status === "suc") {
      set_s_companies(call_getCompanies.data);
    } else if (call_getCompanies.status === "err") {
      set_s_error(call_getCompanies.msg);
    }
  }, [call_getCompanies.status]);

  useEffect(() => {
    if (call_getPaymentPlans.status === "suc"){
      set_s_paymentPlans(call_getPaymentPlans.data)
    } else if (call_getPaymentPlans.status === "err"){
      set_s_error(call_getPaymentPlans.msg)
    }
  }, [call_getPaymentPlans.status])

  useEffect(() => {
    if (s_modalMode === "create") {
      set_s_isModalOpen(true)
    } else if (s_modalMode === "edit" && s_paymentData) {
      // console.log("s_paymentdata", s_paymentData)
      set_s_isModalOpen(true)
    }
  }, [s_modalMode, s_paymentData])

  useEffect(() => {
    if (call_createPaymentPlan.status === "suc"){
      call_getPaymentPlans.request(s_selectedCompany)
    }
  }, [call_createPaymentPlan.status])

  useEffect(() => {
    if (call_updatePaymentPlan.status === "suc"){
      call_getPaymentPlans.request(s_selectedCompany)
    }
  }, [call_updatePaymentPlan.status])

  useEffect(() => {
    if (s_paymentID) {
      set_s_isConfirmationModalOpen(true)
    }
  }, [s_paymentID])

  useEffect(() => {
    if (call_deletePaymentPlan.status === "suc"){
      call_getPaymentPlans.request(s_selectedCompany)
      set_s_paymentID(null)
    } else if (call_deletePaymentPlan.status === "err"){
      // ERROR HANDLING
      set_s_paymentID(null)
    }
  }, [call_deletePaymentPlan.status])

  return (
    <div className="py-12 px-24 gap-4">
        <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">{t("adminPaymentPlan.title")}</h1>
            <CustomButton variant="secondary" className="text-white" onClick={handleCreatePaymentPlan}>
                + {t("adminPaymentPlan.addNewPlan")}
            </CustomButton>
        </div>

        <Select
          onValueChange={handleSelectCompany}
          value={s_selectedCompany}
        >
          <SelectTrigger>
            <SelectValue placeholder={t("adminPaymentPlan.selectPlaceholder")} />
          </SelectTrigger>
          <SelectContent>
            {s_companies.map((company) => (
              <SelectItem key={company.id} value={company.id}>
                {company.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <DataTable
            className="mt-8"
            columns={columns}
            data={s_paymentPlans}
            // onRowClick={() => console.log("rowclicked")}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
        />


        <PaymentModal
          mode={s_modalMode}
          open={s_isModalOpen}
          paymentData={s_paymentData}
          companyId={s_selectedCompany}
          onConfirm={handleModalConfirm}
          onCancel={handleCancelModal}
        />
        

        <ConfirmationModal 
            open={s_isConfirmationModalOpen}
            message={t("adminPaymentPlan.confirmDeletePlan")}
            onConfirm={handleDeletePlan}
            onCancel={() => set_s_isConfirmationModalOpen(false)}
        />
    </div>
  )
}
    
export default PaymentPlanManagement
  