import { createContext, useContext, useEffect, useState } from "react";
import { getUserCredits } from "../apis/users/usersAPi";
import useAPI from "../hooks/useAPI";

const CreditsContext = createContext()

export const CreditsProvider = ({children}) => {
    const [s_credits, set_s_credits] = useState(0)

    const call_getUserCredits = useAPI(getUserCredits)

    useEffect(() => {
        // Update credits when API call succeeds
        if (call_getUserCredits.status === "suc" && call_getUserCredits.data) {
            set_s_credits(call_getUserCredits.data.totalAmount || 0);
        } else if (call_getUserCredits.status === "err") {
            set_s_credits(0);
        }
    }, [call_getUserCredits.status]);



    return (
        <CreditsContext.Provider value={{ s_credits, set_s_credits, call_getUserCredits}}>
            {children}
        </CreditsContext.Provider>
    )
}

export const useCredits = () => useContext(CreditsContext);