import { Eye, EyeOff, X } from "lucide-react"; // Import the X icon or any other icon you prefer
import * as React from "react";
import { cn } from "../../../utils/utils";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input"> & { clearable?: boolean }>(
  ({ className, type, clearable = true, ...props }, ref) => {
    const [value, setValue] = React.useState(props.value || "");
    const [s_isPasswordVisible, set_s_isPasswordVisible] = React.useState(false)
    const isPassword = type === "password"

    const handleClear = () => {
      setValue("");
      if (props.onChange) {
        props.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    React.useEffect(() => {
      setValue(props.value || "");
    }, [props.value]);

    return (
      <div className="relative w-full">
        <input
          type={isPassword && !s_isPasswordVisible ? "password" : "text"}
          className={cn(
            "text-xl flex h-9 w-full rounded-md border border-black200 bg-transparent px-3 py-1 shadow-md transition-colors file:border-0 file:shadow-md file:mr-4 file:rounded-md file:bg-green-500 file:text-green-200 file:font-bold placeholder:text-muted-foreground placeholder:text-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-300",
            clearable && value ? "pr-12" : "pr-3",
            "overflow-visible",
            className
          )}
          ref={ref}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          {...props}
        />
        {clearable && value && (
          <button
            type="button"
            onClick={handleClear}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            style={{
              padding: "4px", // Reduce interactive area
              pointerEvents: "auto", // Ensure button is clickable but doesn't affect input
              background: "transparent", // Make sure it's visually invisible
            }}
          >
            <X className="w-5 h-5" />
          </button>
        )}

        {/* Hold-to-Show Password Button */}
        {isPassword && value && (
          <button
            type="button"
            onMouseDown={() => {set_s_isPasswordVisible(true)}}  // Show password while holding
            onMouseUp={() => {set_s_isPasswordVisible(false)}}   // Hide password when released
            onMouseLeave={() => {set_s_isPasswordVisible(false)}} // Hide if the mouse leaves the button
            className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            {s_isPasswordVisible ? <Eye className="w-5 h-5" /> : <EyeOff className="w-5 h-5" />}  
          </button>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
