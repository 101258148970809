import { createContext, useContext, useEffect, useReducer, useState } from "react";

const NavigationBlockerContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case "BLOCK":
            // console.log("🔒 Blocking Navigation (No modal yet)");
            return { ...state, isBlocked: true };

        case "SHOW_MODAL":
            // console.log("⚠️ Navigation Attempted - Opening Confirmation Modal!");
            return { ...state, isBlocked: true, showModal: true, pendingPath: action.payload };

        case "CONFIRM":
            // console.log("✅ Confirming Navigation!");
            return { ...state, isBlocked: false, showModal: false, pendingPath: action.payload };

        case "CANCEL":
            // console.log("🛑 Cancelling Navigation!");
            return { ...state, showModal: false, pendingPath: null };

        case "NOT_BLOCKED":
            // console.log(" Unblocked")
            return {...state, isBlocked: false, showModal: false}

        default:
            return state;
    }
};

export const NavigationBlockerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, { isBlocked: false, pendingPath: null });

    return (
        <NavigationBlockerContext.Provider value={{ state, dispatch }}>
            {children}
        </NavigationBlockerContext.Provider>
    );
};

// Custom Hook for easy access
export const useNavigationBlockerContext = () => useContext(NavigationBlockerContext);
