import { useState } from "react";
import ConfirmationModal from "./confirmationModal";
import useNavigationBlocker from "../hooks/useNavigationBlocker";
import { useNavigationBlockerContext } from "../context/NavigationBlockerContext";
import { useTranslation } from "react-i18next";

const NavigationBlocker = ({ shouldBlock }) => {
    const { t } = useTranslation()
    const { state, dispatch } = useNavigationBlockerContext();

    // console.log("🛑 NavigationBlocker Rendered - isBlocked:", state.isBlocked);

    return (
        <>
            <ConfirmationModal
                open={state.showModal}
                message={t("caseDetail.navigateOut")}
                messageClassName="text-xl font-bold"
                onConfirm={() => dispatch({ type: "CONFIRM", payload: state.pendingPath })}
                onCancel={() => dispatch({ type: "CANCEL" })}
                yesButton={t("caseDetail.yesButton")}
                noButton={t("caseDetail.noButton")}
            />
        </>
    );
};

export default NavigationBlocker;
