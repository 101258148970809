import { cn } from "../utils/utils";
import { MoreVertical } from "lucide-react";
import { Badge } from "./ui/badge/badge";

const statusStyles = {
  waiting: "bg-yellow-500 text-white text-md",
  running: "bg-blue-500 text-white text-md",
  success: "bg-green-500 text-white text-md",
  error: "bg-red-500 text-white text-md",
};

const StatusRow = ({ fields, description, status, icon, badgeText }) => {
  return (
    <div className="flex items-center justify-between">
      {/* Fields */}
      <div className="flex-1 text-gray-800">{fields}</div>

      {/* Description */}
      <div className="flex-1 text-gray-600">{description}</div>

      {/* Status */}
      <div className="flex items-center space-x-2">
        <Badge className={cn("flex items-center w-24 h-8", statusStyles[status])}>
          {icon}
          <span className="ml-1 text-center">{badgeText}</span>
        </Badge>
      </div>
    </div>
  );
};

export default StatusRow;
