import { useEffect, useState } from "react";

const useLastVisited = () => {
    const [lastVisited, setLastVisited] = useState(null);

    useEffect(() => {
        const storedPath = localStorage.getItem("lastVisited") || "/copilot";
        setLastVisited(storedPath);
    }, []);

    return lastVisited;
};

export default useLastVisited;
