import { cn } from "../../utils/utils";

const Loader = ({ className, size = "md", ...props }) => {
  const sizeClasses = {
    sm: "h-4 w-4 border-2",
    md: "h-6 w-6 border-2",
    lg: "h-8 w-8 border-4",
  };

  return (
    <div
      className={cn(
        "inline-block animate-spin rounded-full border-t-transparent",
        sizeClasses[size],
        className
      )}
      {...props}
    ></div>
  );
};

Loader.displayName = "Loader";

export { Loader };
