import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Dropdown, Grid } from 'semantic-ui-react';
import Cookies from 'universal-cookie';
import { login } from '../apis/users/usersAPi';
import useAPI from '../hooks/useAPI';
import useAuth from '../hooks/useAuth';
import '../styles/login.css';
import { Input } from '../components/ui/input/input';
import { useNavigationBlockerContext } from '../context/NavigationBlockerContext';
import useNavigationBlocker from '../hooks/useNavigationBlocker';

const logo = "/logo.webp"
const background = "/Taipei.webp"
const appVersion = process.env.REACT_APP_VERSION || 'dev'

const Login = () => {
  const { attemptNavigation } = useNavigationBlocker();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const { t, i18n } = useTranslation()

  const call_login = useAPI(login)

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    // try {
    //   const response = await axiosServer.post('/api/users/login', { username, password });

    //   if (response.status === 200) {
    //     const { user, access, refresh } = response.data;

    //     const cookieOptions = remember
    //       ? { path: '/', maxAge: 7 * 24 * 60 * 60, secure: window.location.protocol === 'https:' }
    //       : { path: '/', maxAge: 24 * 60 * 60, secure: window.location.protocol === 'https:' };

    //     const userData = {
    //       id: user.id,
    //       username: user.username,
    //       accessToken: access,
    //       refreshToken: refresh,
    //       role: user.role,
    //     };

    //     cookies.set('authData', JSON.stringify(userData), cookieOptions);
    //     setAuth(userData);
    //     if (user.role === "admin") {
    //       navigate("/admin/dashboard")
    //     } else {
    //       navigate('/home');
    //     }
    //   } else {
    //     throw new Error('Failed to login');
    //   }
    // } catch (error) {
    //   setError(t("login.error || 'Invalid username or password');
    //   errRef.current.focus();
    // }
    call_login.request({username, password});
};

const toggleLanguage = (lang) => {
  i18n.changeLanguage(lang); // Dynamically switch languages
};

// Auto-login: Check cookies when the component mounts
useEffect(() => {
  const storedAuth = cookies.get('authData');
  // console.log("storedauth", storedAuth)
  const storedRemember = cookies.get("rememberMe");
  // console.log("rememberMe", storedRemember)
  if (storedAuth && storedRemember === true) {
    try {
      // Check if storedAuth is already an object or needs parsing
      const parsedAuth = typeof storedAuth === 'string' ? JSON.parse(storedAuth) : storedAuth;

      if (parsedAuth?.accessToken) {
        // Set auth context
        setAuth(parsedAuth);

        // Redirect user based on role
        if (parsedAuth.role === 'admin') {
          attemptNavigation('/admin/dashboard');
        } else {
          attemptNavigation('/copilot');
        }
      }
    } catch (error) {
      console.error('Error parsing authData cookie:', error);
    }
  }
}, []);

useEffect(() => {
  // console.log("call_loginstatus", call_login.status)
  if (call_login.status === "suc") {
    const {user, access, refresh} = call_login.data;

    const cookieOptions = remember
    ? { path: '/', maxAge: 7 * 24 * 60 * 60, secure: window.location.protocol === 'https:' }
    : { path: '/', maxAge: 24 * 60 * 60, secure: window.location.protocol === 'https:' };

    const userData = {
      id: user.id,
      username: user.username,
      accessToken: access,
      refreshToken: refresh,
      role: user.role,
      companyID: user.companyID
    };

    // Store user data in cookies and context
    cookies.set("rememberMe", remember ? "true" : "false", cookieOptions);
    cookies.set('authData', JSON.stringify(userData), cookieOptions);
    setAuth(userData);

    // Navigate based on user role
    if (user.role === 'admin') {
      navigate('/admin/dashboard');
    } else {
      navigate('/copilot');
    }
  } else if (call_login.status === "err") {
    // console.log("login response:", call_login)
    if (call_login.msg === "Authentication Failed 401") {
      setError(t("login.invalidCredentials"))
    } else {
      setError(t("login.errorLogin"))
    }
    errRef.current.focus();
  }
}, [call_login.status])


  return (
    <section className="login-section">
      <Grid columns={11} style={{ margin: '0px', padding: '0px', minHeight: '100vh' }}>
        <Grid.Column className="login-left-design">
          <img src={background} alt="Background" className="login-rounded-image" loading="lazy" />
        </Grid.Column>
        <Grid.Column width={6} style={{ padding: '0', backgroundColor: '#ffffff' }}>
          <div className="login-right-design-container">
            <div className="login-lang-settings">
              <Dropdown
                defaultValue={i18n.language}
                options={[
                  { key: 'en', value: 'en', text: 'English' },
                  { key: 'zh', value: 'zh', text: '中文' },
                ]}
                onChange={(e, data) => toggleLanguage(data.value)}
              />
            </div>
            <div className="login-right-design">
              <center>
                <img src={logo} alt="Logo" className="login-rounded-image" loading="lazy"  />
                <h2 className="login-h2">{t("welcome")}</h2>
                <p ref={errRef} className={error ? 'login-errmsg' : 'login-offscreen'} aria-live="assertive">
                  {error}
                </p>
              </center>
              <form onSubmit={handleLogin}>
                <div className="login-inputs">
                  <span className="login-label">{t("login.username")}</span>
                  <Input
                    placeholder={t("login.username")}
                    type="text"
                    clearable={false}
                    ref={userRef}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className="text-[15px] mt-1 shadow-none rouded-none h-10"
                    required
                    fluid
                  />
                </div>
                <div className="login-inputs">
                  <span className="login-label">{t("login.password")}</span>
                  <Input
                    placeholder={t("login.password")}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className="text-[15px] mt-1 shadow-none rouded-none h-10"
                    required
                    fluid
                  />
                  <div className="login-rem-forgot">
                    <Grid columns={2}>
                      <Grid.Column textAlign="left">
                        <Checkbox
                          label={t("login.rememberMe")}
                          className="login-remember-me"
                          checked={remember}
                          onChange={(e, data) => setRemember(data.checked)}
                        />
                      </Grid.Column>
                    </Grid>
                  </div>
                </div>
                <div>
                  <Button fluid style={{ backgroundColor: '#47B4EB', color: '#FFFFFF', borderRadius: '6px' }}>
                    {t("login.loginButton")}
                  </Button>
                </div>
              </form>
            </div>
            <div className='flex w-full text-xs text-gray-400 justify-end mt-6 px-14'>
                v{appVersion}
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </section>
  );
};

export default Login;
