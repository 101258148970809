import { Navigate } from "react-router-dom";
import useLastVisited from "../hooks/useLastVisited";

const RedirectToLastVisited = () => {
    const lastVisited = useLastVisited();

    if (!lastVisited) return null; // Prevent early redirect before localStorage loads

    return <Navigate to={lastVisited} replace />;
};

export default RedirectToLastVisited;
