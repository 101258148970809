import { NavLink } from "react-router-dom";
import { useSidebarItems } from "../../sidebar";

const Sidebar = ({ role = "admin" }) => {
    const sidebarItems = useSidebarItems();

    // Get items based on role
    const roleItems = sidebarItems[role] || [];

    return (
        <div className="h-full flex flex-col py-6 space-y-4 bg-white border border-r-gray">
            <div className="px-4">
                <h1 className="text-2xl font-bold h-10"></h1>
            </div>
            <div className="flex-1 pl-2 pr-6 space-y-2">
                {roleItems.map((item, index) => (
                <div key={index} className="mb-4">
                    {/* Parent Item (Non-clickable) */}
                    <div className="flex items-center text-md p-2 font-bold text-black">
                        {item.icon && <span className="mr-4">{item.icon}</span>}
                        {item.label}
                    </div>

                    {/* Subitems */}
                    {item.items && (
                    <div className="ml-3 mt-2 space-y-1">
                        {item.items.map((subitem, subIndex) => (
                        <NavLink
                            key={subIndex}
                            to={subitem.disabled ? "#" : subitem.path}
                            className={({ isActive }) =>
                            `flex items-center text-sm hover:text-black p-2 rounded-sm ${
                                subitem.disabled
                                ? "text-gray-400 cursor-not-allowed hover:disabled"
                                : "hover:bg-gray-100 hover:text-black"
                            } ${
                                isActive && !subitem.disabled
                                ? "text-black font-semibold bg-gray-100"
                                : "text-black"
                            }`
                            }
                            onClick={(e) => subitem.disabled && e.preventDefault()}
                        >
                            {subitem.icon && <span className="mr-4">{subitem.icon}</span>}
                            {subitem.label}
                        </NavLink>
                        ))}
                    </div>
                    )}
                </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
