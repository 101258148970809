import CopilotInputFrame from '../components/copilotInputFrame';
import { withAuthProtection } from '../context/AuthProvider'; // Importing the HOC

const Copilot = () => {
  return (
    <div className="w-full flex items-center justify-center">
        <CopilotInputFrame />
    </div>
  );
};

export default withAuthProtection(Copilot); // Wrapping the component with the HOC
