import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import CustomButton from "../../components/reusable/ButtonComponent"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card/card"

const AdminDashboard = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const menuItems = [
        {
            title: t("dashboard.userManagement"),
            description: t("dashboard.userManagementDesc"),
            buttons: [
                { label: t("dashboard.manageUsers"), route: "/admin/user-management" },
                { label: t("dashboard.manageCompanies"), route: "/admin/companies-management", disabled: true },
                { label: t("dashboard.manageActivities"), route: "/admin/user-activities", disabled: false },
                // { label: t("dashboard.userCredits"), route: "/admin/user-credits-management" },
                // { label: t("dashboard.linkedAccounts"), route: "/admin/user-linked-accounts" },
                // { label: t("dashboard.userActivities"), route: "/admin/user-activities" },
            ],
        },
        {
            title: t("dashboard.transactionsManagement"),
            description: t("dashboard.transactionManagementDesc"),
            buttons: [
                { label: t("dashboard.transactionActivities"), route: "/admin/transaction-activities", disabled: true },
                // { label: t("dashboard.pendingTransactions"), route: "/admin/pending-transactions" },
                // { label: t("dashboard.completedTransactions"), route: "/admin/completed-transactions" },
            ],
        },
        {
            title: t("dashboard.paymentManagement"),
            description: t("dashboard.paymentManagementDesc"),
            buttons: [
                { label: t("dashboard.managePaymentPlans"), route: "/admin/payment-plans" },
                { label: t("dashboard.paymentHistory"), route: "/admin/payment-history", disabled: true },
                { label: t("dashboard.failedPayments"), route: "/admin/failed-payments", disabled: true },
                { label: t("dashboard.acceptPayments"), route: "/admin/accept-payments", disabled: true },
                // { label: t("dashboard.paymentGateway"), route: "/admin/payment-gateway" },
            ],
        },
        {
            title: t("dashboard.logs"),
            description: t("dashboard.logsDesc"),
            buttons: [
                { label: t("dashboard.errorLogs"), route: "/admin/error-logs", disabled: true },
                { label: t("dashboard.activityLogs"), route: "/admin/activity-logs", disabled: true },
            ],
        },
    ]


    return (
    <div className="px-6 py-12">
        <h1 className="text-3xl font-bold mb-8">{t("dashboard.adminDashboard")}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {menuItems.map((item, index) => (
                <Card
                    key={index}
                    className="hover:shadow-lg transition-transform transform hover:scale-105"
                >
                    <CardHeader>
                        <CardTitle className="text-xl">{item.title}</CardTitle>
                        <CardDescription>{item.description}</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col space-y-2">
                            {item.buttons.map((button, btnIndex) => (
                                <CustomButton 
                                    key={btnIndex}
                                    onClick={() => navigate(button.route)}
                                    disabled={button.disabled}
                                >
                                    {button.label}
                                </CustomButton>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    </div>
)}

export default AdminDashboard
