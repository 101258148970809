import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { } from "../../context/language";
import CustomButton from "../reusable/ButtonComponent";
import { Dialog, DialogContent, DialogFooter, DialogTitle } from "../ui/dialog/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form/form";
import { Input } from "../ui/input/input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select/select";
import useAPI from "../../hooks/useAPI";
import { getCompanies } from "../../apis/company/companyApi";

const UserModal = ({ open, userData, mode="edit", onConfirm, onCancel, errorMessage }) => {
    const { t } = useTranslation();
    const [s_companies, set_s_companies] = useState([])

    const form = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            id: userData?.id || null,
            username: userData?.username || "",
            role: userData?.role || "user",
            password: "",
            company: userData?.company || ""
        },
    });

    // console.log("type company", typeof userData.company)
    const { handleSubmit, reset, formState: { errors } } = form;

    const call_getCompanies = useAPI(getCompanies)

    const handleFormSubmit = (data) => {
        onConfirm(data);
    };

    useEffect(() => {
        call_getCompanies.request()
    }, [])

    useEffect(() => {
        reset({
            id: userData?.id || null,
            username: userData?.username || "",
            role: userData?.role || "user",
            password: "",
            company: userData?.company || "" 
        });
    }, [userData, reset]);

    useEffect(() => {
        if (call_getCompanies.status === "suc"){
            // console.log("companies:", call_getCompanies.data)
            set_s_companies(call_getCompanies.data)
        } else if (call_getCompanies.status === "err"){
            console.error(`Error getting companies: ${call_getCompanies.msg}`)
        }
    }, [call_getCompanies.status])


    return (
        <Dialog open={open} onOpenChange={onCancel} className="pt-6">
            <DialogContent className="text-center space-y-4">
                <DialogTitle>
                    <h3 className="text-xl font-bold">
                        {mode === "edit" ? t("userModal.editUser") : t("userModal.createUser")}
                    </h3>
                </DialogTitle>
                
                {errorMessage && <p className="text-red-500 text-md">{errorMessage}</p>}

                <Form {...form}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        {/* Username Input */}
                        <FormField
                            name="username"
                            control={form.control}
                            rules={{ required: 'This input is required' }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("userModal.username")}
                                    </FormLabel>
                                    <FormControl>
                                        <Input disabled={mode === "edit"} {...field} placeholder={t("userModal.usernamePlaceholder")} />
                                    </FormControl>
                                    <FormMessage>{errors.username?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        {/* Password input for Create User */}
                        {mode === "create" && (
                            <FormField
                                name="password"
                                control={form.control}
                                rules={{required: 'This input is required'}}
                                render={({field}) => (
                                    <FormItem className="flex flex-col w-full items-start mb-6">
                                        <FormLabel className="text-right text-md font-semibold mr-2">
                                            {t("userModal.password")}
                                        </FormLabel>
                                        <FormControl>
                                            <Input type="password" {...field} placeholder={t("userModal.passwordPlaceholder")} />
                                        </FormControl>
                                        <FormMessage>{errors.password?.message}</FormMessage>
                                    </FormItem>
                                )}
                            />
                        )}

                        {/* Companies Selection */}
                        <FormField 
                            name="company"
                            control={form.control}
                            rules={{required: "Company is required"}}
                            render={({field}) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("userModal.company")}
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="border rounded-md p-2">
                                                {field.value ? s_companies.find(c => c.id === field.value)?.name : t("userModal.companyPlaceholder")}
                                            </SelectTrigger>
                                            <SelectContent>
                                                {s_companies.map((company) => (
                                                    <SelectItem key={company.id} value={company.id.toString()}>
                                                        {company.name}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage>{errors.company?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        {/* Role Select */}
                        <FormField
                            name="role"
                            control={form.control}
                            rules={{ required: "Role is required" }}
                            render={({ field }) => (
                                <FormItem className="flex flex-col w-full items-start mb-6">
                                    <FormLabel className="text-right text-md font-semibold mr-2">
                                        {t("userModal.role")}
                                    </FormLabel>
                                    <FormControl>
                                        <Select
                                            onValueChange={field.onChange}
                                            value={field.value}
                                        >
                                            <SelectTrigger className="border rounded-md p-2">
                                                {field.value ? t(`userModal.${field.value}`) : t("userModal.rolePlaceholder")}
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="user">{t("userModal.user")}</SelectItem>
                                                <SelectItem value="admin">{t("userModal.admin")}</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage>{errors.role?.message}</FormMessage>
                                </FormItem>
                            )}
                        />

                        <DialogFooter className="flex justify-end mt-4 space-x-4">
                            <CustomButton variant="default" onClick={onCancel}>
                                {t("userModal.cancel")}
                            </CustomButton>
                            <CustomButton
                                variant="primary"
                                onClick={handleSubmit(handleFormSubmit)}
                                disabled={Object.keys(errors).length > 0}
                            >
                                {mode === "edit" ? t("userModal.save") : t("userModal.create")}
                            </CustomButton>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export default UserModal;
