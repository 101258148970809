import { Clock } from "lucide-react";
import { useTranslation } from "react-i18next";
import StatusRow from "../statusRow";

const StatusWaiting = (props) => {
  const { t } = useTranslation();
  return (
    <StatusRow
      fields={props.fields}
      description={props.description}
      status="waiting"
      icon={<Clock className="w-4 h-4 text-white" />}
      badgeText={t("status.waiting") || "等待中"}
    />
  );
};

export default StatusWaiting;
