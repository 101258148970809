import { BadgeDollarSign, ChevronDown, FolderPlus, Home, List, LogOut, Settings } from "lucide-react";
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Cookies from 'universal-cookie';
import { AuthContext } from '../context/AuthProvider';
import { useCredits } from '../context/CreditsProvider';
import { Button } from './ui/button/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/menu/dropdown';
import useAPI from "../hooks/useAPI";
import { getBackgroundProcessStatus } from "../apis/script/scriptApi";
import ConfirmationModal from "./confirmationModal";
import { getAccount } from "../apis/account/accountApi";
import useNavigationBlocker from "../hooks/useNavigationBlocker";
import { useNavigationBlockerContext } from "../context/NavigationBlockerContext";
import { useLocation } from "react-router-dom";
import Spinner from "./ui/spinner/spinner";

const userIcon = "/usernameicon.svg"
const credits = "/ticket.svg"

const Navbar = () => {
  const { state, dispatch } = useNavigationBlockerContext();
  const { attemptNavigation } = useNavigationBlocker();
  const location = useLocation()
  const cookies = new Cookies();
  const { auth, setAuth, user } = useContext(AuthContext);
  const { s_credits, call_getUserCredits } = useCredits();
  const { t, i18n } = useTranslation();
  const [s_isModalOpen, set_s_isModalOpen] = useState(false);
  const [s_haveAccount, set_s_haveAccount] = useState(false);
  const [s_loading, set_s_loading] = useState(false);

  const isAdmin = auth?.role === "admin"

  const call_getAccount = useAPI(getAccount)
  const call_getBackgroundProcessStatus = useAPI(getBackgroundProcessStatus)

  const handleLogout = () => {
    setAuth(null);
    cookies.remove('authData', { path: '/' });
    handleNavigation('/login');
    sessionStorage.removeItem("sessionActive"); // Ensure session clears
    cookies.remove("authData", { path: "/" });
    cookies.remove("rememberMe", { path: "/" });
    setAuth(null);
    handleNavigation("/login");
  };

  const handleAccountSettings = () => {
    handleNavigation('/account-settings/manage');
  };

  const toggleLanguage = (lang) => {
    i18n.changeLanguage(lang); // Dynamically switch languages
  };

  const goToCopilot = () => {
    set_s_loading(true)
    call_getBackgroundProcessStatus.request()
  }

  const handleNavigation = (path) => {
    // console.log("🟡 Handling navigation to:", path);
    attemptNavigation(path);
};

  useEffect(() => {
    if (location.pathname === "/copilot") {
      dispatch({ type: "CONFIRM", payload: "/copilot" })
    }
  }, [])
  
  useEffect(() => {
    call_getAccount.request()
  }, [])

  useEffect(() => {
    if (!isAdmin && auth?.id){
      call_getUserCredits.request(auth.id)
    }
  }, [auth?.id])


  useEffect(() => {
    if (call_getAccount.status === "suc") {
      set_s_haveAccount(call_getAccount.data.length === 2)
    } else if (call_getAccount.status === "err" ){
      console.error(call_getAccount.msg)
    }
  }, [call_getAccount.status])
  
  useEffect(() => {
    if (call_getBackgroundProcessStatus.status === "suc") {
      const isRunning = call_getBackgroundProcessStatus.data.is_running
      // console.log("Isrunning:", isRunning)
      if (isRunning) {
        set_s_loading(false)
        set_s_isModalOpen(true)
      } else {
          set_s_loading(false)
          handleNavigation('/copilot')
      }
    } else if (call_getBackgroundProcessStatus.status === "err" ){
      console.error(call_getBackgroundProcessStatus.msg)
      set_s_loading(false)
    }
  }, [call_getBackgroundProcessStatus.status])
  
  
  if (s_loading) return (
    <Spinner />
  )


  return (
    <nav className='fixed top-0 left-0 w-full bg-primary text-white shadow-md px-4'>
      <div className='flex justify-between items-center px-4 h-16'>
        <Button 
          variant="ghost" 
          className="flex h-full items-center px-4 py-2 rounded-lg bg-transparent hover:bg-secondary hover:text-secondary-foreground transition duration-300"
          onClick={() => handleNavigation(isAdmin ? "/admin/dashboard" : "/copilot")}
        >
          <Home className="w-6 h-6" /> 
        </Button>

        <div className='flex h-full items-center align-baseline space-x-2'>
          {!isAdmin && (
            <>
              <Button 
                variant="ghost" 
                className="flex h-full text-lg font-semibold items-center px-4 rounded-sm bg-transparent hover:bg-secondary hover:text-secondary-foreground transition duration-300"
                onClick={() => goToCopilot("/copilot")}
              >
                {t("navbar.copilotNavButton")}
              </Button>
              <Button 
                variant="ghost" 
                className="flex h-full text-lg font-semibold items-center px-4 rounded-sm bg-transparent hover:bg-secondary hover:text-secondary-foreground transition duration-300"
                onClick={() => handleNavigation("/home")}
              >
                {t("navbar.caseListNavButton")}
              </Button>
              <Button 
                variant="ghost" 
                className="flex h-full text-lg font-semibold items-center px-4 rounded-sm bg-transparent hover:bg-secondary hover:text-secondary-foreground transition duration-300"
                onClick={() => handleNavigation("/account-settings/payment-plan")}
              >
                {t("navbar.paymentPlanButton")}
              </Button>
            </>
          )}

          {!isAdmin && (
            <div className="flex align-baseline items-center gap-2 text-lg font-semibold">
              
              {t("navbar.credits")}
              <img
                src={credits}
                alt="Credits Icon"
                className="w-6 h-6"
              /> 
              {s_credits}
            </div>
          )}
          
          {isAdmin && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button 
                  variant='ghost'
                  className="hover:bg-secondary hover:text-secondary-foreground text-lg"
                >
                  {i18n.language === "en" ? "English" : "中文"}
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent align='end'>
                  <DropdownMenuItem className="hover:bg-primary hover:text-primary-foreground" onClick={() => toggleLanguage("en")}>
                    English
                  </DropdownMenuItem>
                  <DropdownMenuItem className="hover:bg-primary hover:text-primary-foreground" onClick={() => toggleLanguage("zh")}>
                    中文
                  </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                  variant="ghost"
                  className="h-full hover:bg-secondary hover:text-secondary-foreground"
                >
                  <img
                    src={userIcon}
                    alt="User Icon"
                    className="w-8 h-8"
                  />
                  <ChevronDown className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="bg-card text-card-foreground">
              <DropdownMenuItem className="hover:bg-primary hover:text-primary-foreground hover:cursor-pointer" onClick={handleAccountSettings}>
                <Settings className='w-4 h-4' />
                {t("navbar.accountSettings")}
              </DropdownMenuItem>

              <DropdownMenuItem className="hover:bg-primary hover:text-primary-foreground hover:cursor-pointer" onClick={handleLogout}>
                <LogOut className='w-4 h-4' />
                {t("navbar.logout")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      
      <ConfirmationModal 
        open={s_isModalOpen}
        message={t("home.backgroundProcess")}
        onConfirm={() => set_s_isModalOpen(false)}
        onCancel={() => set_s_isModalOpen(false)}
      />
    </nav>
  );
};

export default Navbar;

