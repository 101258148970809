import { instance } from "../handlerAPI"

export const getPaymentPlansByID = (companyId) => {
    return instance.get(`/api/payment-plan/lists-by-id?company_id=${companyId}`)
}

export const getAllPaymentPlans = () => {
    return instance.get('/api/payment-plan/lists')
}

export const createPaymentPlan = (data) => {
    return instance.post('/api/payment-plan/create', data)
}

export const updatePaymentPlan = ({paymentID, paymentData}) => {
    return instance.put(`/api/payment-plan/update/${paymentID}`, paymentData)
}

export const deletePaymentPlan = (planID) => {
    return instance.delete(`/api/payment-plan/delete/${planID}`)
}

export const buyPlan = (data) => {
    return instance.post('/api/payment-plan/buy', data)
}