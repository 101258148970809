import { Navigate } from 'react-router-dom';
import LinkedAccounts from './components/linkedAccounts';
import { ProtectedRoute } from './context/AuthProvider';
import MainLayout from './MainLayout';
import AccountSettings from './pages/accountSettings';
import AdminDashboard from './pages/admin/adminDashboard';
import PaymentPlanManagement from './pages/admin/paymentPlanManagement';
import UserManagement from './pages/admin/userManagement';
import CaseDetail from './pages/caseDetail';
import Copilot from './pages/copilot';
import Home from './pages/home';
import Login from './pages/login';
import Output from './pages/output';
import PaymentPlan from './pages/paymentPlan';
import UserActivitiesManagement from './pages/admin/userActivitiesManagement';
import RedirectToLastVisited from './components/redirectToLastVisited';

const routes = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/',
        element: <ProtectedRoute element={MainLayout} />,
        children: [
            { path: '', element: <RedirectToLastVisited /> },
            { path: '/home', element: <Home />, },
            { path: '/copilot', element: <Copilot /> },
            { path: '/output', element: <Output /> },
            { path: '/cases/:caseID', element: <CaseDetail />,},
            { path: '/account-settings/manage', element: <AccountSettings />, showSidebar: true },
            // { path: '/account-settings/edit', element: <AccountEdit /> },
            { path: '/account-settings/linked-accounts', element: <LinkedAccounts /> },
            { path: '/account-settings/payment-plan', element: <PaymentPlan />, showSidebar: true },
            // Admin-only routes
            { path: '/admin/dashboard', element: <ProtectedRoute element={AdminDashboard} requiredRole="admin" />, showSidebar: true },
            { path: '/admin/user-management', element: <ProtectedRoute element={UserManagement} requiredRole="admin" />, showSidebar: true },
            { path: '/admin/user-activities', element: <ProtectedRoute element={UserActivitiesManagement} requiredRole="admin" />, showSidebar: true },
            { path: '/admin/payment-plans', element: <ProtectedRoute element={PaymentPlanManagement} requiredRole="admin" />, showSidebar: true },
            { path: '*', element: <Navigate to="/copilot" /> },
        ],
    },
];

export default routes;
